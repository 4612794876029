import LogRocket from "logrocket";
import { parseEnvVarToStringArray } from "./envUtils";

export function initLogRocket(applianceId: string, username: string): void {
  // Return early if applianceId or enabledAppliances are blank as performance optimisation
  // so we're not doing an expensive env var parse or include-check if not necessary.

  if (!applianceId) return;
  if (!username) return;

  const disabledUsers = parseEnvVarToStringArray(process.env.REACT_APP_LOGROCKET_DISABLED_USERS);
  if (disabledUsers.includes(username)) return;

  const excludedAppliances = parseEnvVarToStringArray(process.env.REACT_APP_LOGROCKET_EXCLUDED_APPLIANCE_IDS);

  // Don't initalise Log Rocket for demo and testing appliances
  if (excludedAppliances.includes(applianceId)) return;

  //delayed initialisation to avoid lag due to high memory consumption
  setTimeout(() => {
    LogRocket.init(process.env.REACT_APP_LOGROCKET_APP_ID || "", {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore - LogRocket have confirmed via email that this is a valid config option, despite it not being in their IOptions type definition
      inactivityTimeThreshold: process.env.REACT_APP_LOGROCKET_INACTIVITY_THRESHOLD_TIME,
    });

    const user_identifier = username + "_" + applianceId;
    LogRocket.identify(user_identifier, {
      email: username,
      schoolManagerDeviceID: applianceId,
    });
  }, 3000);
}
