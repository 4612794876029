import { changeLanguage, Flex, initI18n, ThemeProvider } from "@familyzone/component-library";
import PropTypes from "prop-types";
import React, { Fragment } from "react";
import Helmet from "react-helmet";

import { browserHistory, hashHistory, Redirect, Route, Router } from "react-router";

import en_uk from "../../translations/en_uk.json";
import en_XX from "../../translations/en_XX.json";

import ApiActions from "../actions/ApiActions";
import ConfigActions from "../actions/ConfigActions";
import GlobalDatePickerVisibilityActions from "../actions/GlobalDatePickerVisibilityActions";
import SessionActions from "../actions/SessionActions";
import Account from "../components/Account";
import AnnouncementBar from "../components/AnnouncementBar";
import Authorization from "../components/Authorization";
import PermanentAssociations from "../components/config/Associations";
import PermanentAssociationsExceptions from "../components/config/AssociationsExceptions";
import Authentication from "../components/config/Authentication";
import AuthenticationManagePortal from "../components/config/AuthenticationManagePortal";
import AzureConfigurationLink from "../components/config/SyncConfig/AzureConfigurationLink";
import BlockPage from "../components/config/BlockPage";
import Classwize from "../components/config/Classwize";
import ClasswizeLicencing from "../components/config/ClasswizeLicencing";
import CleverConfiguration from "../components/config/SyncConfig/CleverConfiguration";
import CleverConfigurationLink from "../components/config/SyncConfig/CleverConfigurationLink";
import ConfigurationMitm from "../components/config/ConfigurationMitm";
import Courses from "../components/config/Courses";
import EfrontConfig from "../components/config/EfrontConfig";
import { ValidateExpectedCommunityFlags } from "../types/Community";

import CyberSafety from "../components/config/CyberSafety";
import Dns from "../components/config/Dns";
import { GoogleConfiguration } from "./config/SyncConfig/GoogleConfiguration";
import GoogleConfigurationLink from "../components/config/SyncConfig/GoogleConfigurationLink";
import GoogleCustomerConfigurationLink from "../components/config/GoogleCustomerConfigurationLink";
import GuestPrinter from "../components/config/GuestPrinter";
import Guests from "../components/config/Guests";
import HighAvailability from "../components/config/HighAvailability";
import Interface from "../components/config/Interface";
import LdapConfiguration from "../components/config/SyncConfig/LdapConfiguration";
import LdapServers from "../components/config/SyncConfig/LdapServers";
import MobileAgentConfiguration from "../components/config/MobileAgentConfiguration";
import Period from "../components/config/Period";
import Periods from "../components/config/Periods";
import Routes from "../components/config/Routes";
import SchoolCalendar from "../components/config/SchoolCalendar";
import SecurityGroup from "../components/config/SecurityGroup";
import SecurityGroups from "../components/config/SecurityGroups";
import SyslogConfiguration from "../components/config/SyslogConfiguration";
import Timeout from "../components/config/Timeout";
import Timeouts from "../components/config/Timeouts";
import WalledGarden from "../components/config/WalledGarden";
import DiagnosticsAdvancedConfiguration from "../components/debug/DiagnosticsAdvancedConfiguration";
import AccessDenied from "../components/AccessDenied";

import DiagnosticsAlarms from "../components/debug/DiagnosticsAlarms";
import DiagnosticsCoredumps from "../components/debug/DiagnosticsCoredumps";
import DiagnosticsInterfaceMetrics from "../components/debug/DiagnosticsInterfaceMetrics";
import DiagnosticsLogfiles from "../components/debug/DiagnosticsLogfiles";
import DiagnosticsMetrics from "../components/debug/DiagnosticsMetrics";
import DiagnosticsMetricsAdvanced from "../components/debug/DiagnosticsMetricsAdvanced";
import ManageDeviceFeatureFlags from "../components/debug/ManageDeviceFeatureFlags";
import ManageDevicePermissions from "../components/debug/ManageDevicePermissions";

import ManageDeviceSettings from "../components/debug/ManageDeviceSettings";
import ManageDeviceSnapshots from "../components/debug/ManageDeviceSnapshots";
import ManageDeviceUpdates from "../components/debug/ManageDeviceUpdates";
import DeviceCreation from "../components/DeviceCreation";

import Devices from "../components/Devices";
import CloudSafe from "../components/filtering/CloudSafe";
import Login from "../components/Login";
import LoginGoogle from "../components/LoginGoogle";
import LoginRegisterAwaitingVerification from "../components/LoginRegisterAwaitingVerification";
import LoginRegisterVerifyCode from "../components/LoginRegisterVerifyCode";
import LoginResetPassword from "../components/LoginResetPassword";
import LoginResetPasswordCheckEmail from "../components/LoginResetPasswordCheckEmail";
import LoginResetPasswordConfirm from "../components/LoginResetPasswordConfirm";

import EventsNew from "../components/reporting/EventsNew";
import ReportingBlocked from "../components/reporting/ReportingBlocked";
import ReportingDestinationIpUsers from "../components/reporting/ReportingDestinationIpUsers";
import ReportingDestinationPortUsers from "../components/reporting/ReportingDestinationPortUsers";
import ReportingSearch from "../components/reporting/ReportingSearch";
import ReportingSearchUserAudit from "../components/reporting/ReportingSearchUserAudit";
import ReportingSearchUsersNew from "../components/reporting/ReportingSearchUsersNew";
import ReportingTopDestinationIps from "../components/reporting/ReportingTopDestinationIps";
import ReportingTopDestinationPorts from "../components/reporting/ReportingTopDestinationPorts";
import ReportingTopHosts from "../components/reporting/ReportingTopHosts";

import ReportingTopTypes from "../components/reporting/ReportingTopTypesNew";
import ReportingTopTypeUsers from "../components/reporting/ReportingTopTypeUsers";
import ReportingTopUsersNew from "../components/reporting/ReportingTopUsersNew";
import ReportingTopWebsiteUsers from "../components/reporting/ReportingTopWebsiteUsers";
import ReportingTypeUserNew from "../components/reporting/ReportingTypeUserNew";
import ReportingViolations from "../components/reporting/ReportingViolations";
import ReportingViolationUser from "../components/reporting/ReportingViolationUser";
import ReportingViolationUserDay from "../components/reporting/ReportingViolationUserDay";
import ReportingWebsiteUser from "../components/reporting/ReportingWebsiteUser";
import ReportingWelfare from "../components/reporting/ReportingWelfare";
import VideoDashboard from "../components/reporting/VideoDashboard";
import VideoDetails from "../components/reporting/VideoDetails";

import LookerPageWrapper from "../components/reports/LookerPageWrapper";
import SelectDevice from "../components/SelectDevice";
import ClientVpn from "../components/wan/ClientVpn";

import EdgewizeDashboard from "../components/wan/EdgewizeDashboard";
import Firewall from "../components/wan/Firewall";

import GatewayToGatewayVpn from "../components/wan/GatewayToGatewayVpn";
import GatewayToGatewayVpns from "../components/wan/GatewayToGatewayVpns";
import ManyToOneNat from "../components/wan/ManyToOneNat";

import Mdns from "../components/wan/Mdns";
import MdnsEdit from "../components/wan/MdnsEdit";
import NatExceptions from "../components/wan/NatExceptions";
import OneToOneNat from "../components/wan/OneToOneNat";

import PriorityQos from "../components/wan/PriorityQos";
import RatelimiterQos from "../components/wan/RatelimiterQos";

import Wan from "../components/wan/Wan";
import WanPortForwarding from "../components/wan/WanPortForwarding";
import BypassCodesActive from "../pages/filtering/bypass/Active/BypassCodeActive";
import BypassAvailable from "../pages/filtering/bypass/available/BypassCodesAvailable";

import ContentAwareConfigPage from "./filtering/ContentAware";
import ContentModPage from "../pages/filtering/modifications";
import WebFilteringNew from "../pages/filtering/policies";
import ReportsAndAlerts from "../pages/filtering/reports";
import SafeSearch from "../pages/filtering/safesearch";
import DeviceStatusStore from "../stores/DeviceStatusStore";

import SessionStore from "../stores/SessionStore";
import Layout from "../utils/LayoutConfig";

import AccessDeviceVerification from "./AccessDeviceVerification";
// import FeatureLab from "../pages/config/FeatureLab";
import ChatWidget from "./ChatWidget";
import AdvancedAuthenticationConfiguration from "./config/AdvancedAuthenticationConfiguration";
import AgentAuthentication from "./config/AgentAuthentication";
import { AzureConfiguration } from "./config/SyncConfig/AzureConfiguration";
import Classroom from "./config/Classrooms/Classroom";
import { ClassroomsNew } from "./config/Classrooms/ClassroomsNew";
import ClassroomScreenVisibilityRestrictions from "./config/ClassroomScreenVisibilityRestrictions";
import ClassroomFeatureFlags from "./config/ClassroomFeatureFlags";
import CommunityConfiguration from "./config/CommunityConfiguration";
import CommunityDashboard from "./config/CommunityDashboard";

import ConfigurationDashboard from "./config/ConfigurationDashboard/ConfigurationDashboard";
import GroupsTable from "./config/Groups/GroupsTable";
import ManageGroup from "./config/Groups/ManageGroup";
import Interfaces from "./config/interfaces/Interfaces";
import ManageUserById from "./config/ManageUser/ManageUserById";
import MobileAgentDownloads from "./config/MobileAgentDownloads";
import EditObjectPools from "./config/Objects/EditObjectPools";

import ObjectPools from "./config/Objects/ObjectPools";
import ParentDataUploadNew from "./config/ParentDataUploadNew";
import ParentManagement from "./config/ParentManagement";
import RadiusConfiguration from "./config/RadiusConfiguration";

import Users from "./config/Users/Users";
import WmiConfiguration from "./config/wmi-configuration/WmiConfiguration";
import ReactRouterCompatProvider from "./ContextProvider";
import Netconsole from "./debug/Netconsole";
import { DnsFiltering } from "./filtering/DnsFiltering";
import OutsideSchoolHours from "./filtering/OutsideSchoolHours";

import HeaderBar from "./header";
import Nav from "./nav";
import { ActiveConnections } from "./reporting/ActiveConnections/ActiveConnections";
import { ActiveHosts } from "./reporting/ActiveHosts/ActiveHosts";
import Dashboard from "./reporting/Dashboard/Dashboard";
import ReportingUserTimeline from "./reporting/ReportingUserTimeline";

import UserDashboardIndex from "./reporting/UserDashboard/UserDashboardIndex";
import UserJourney from "./reporting/UserJourney/UserJourney";
import { SisIntegration } from "./config/sis-integration/SisIntegration";
import { useCommunityFeatureFlagStore } from "../storez/CommunityFeatureFlagStore";
import { useAccountStore } from "../storez/AccountStore";
import { useFeatureFlagStore } from "../storez/FeatureFlagStore";
import { HasExpectedFlags } from "../types/FeatureFlags";
import { useDeviceOptionsStore } from "../storez/DeviceOptionsStore";
import ConfigAudit from "./debug/ConfigAudit/ConfigAudit";
import { useDevicePermissionsStore } from "../storez/DevicePermissionsStore";
import queryClient from "../utils/QueryClient";
import { useUserStore } from "../storez/UserStore";
import { useClassroomFeatureFlagStore } from "../storez/ClassroomFeatureFlagStore";
import FilterLicensesPage from "./config/Licenses/Filter";
import { useFilterLicensesStore } from "../storez/FilterLicensesStore";

const PERMISSIONS = {
  classwize: ["owner", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"],
};

class App extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      menuFeatureToggles: {},
    };
  }

  /**
   * Setup the listeners for the stores when AppRoot mounts
   */
  componentDidMount() {
    SessionStore.listen(this.onChange);

    const featureFlags = useFeatureFlagStore.getState().flags;
    if (featureFlags) {
      this.onChangeFeatureFlags(featureFlags);
    }
    this.unsubcribeFeatureFlags = useFeatureFlagStore.subscribe((state) => this.onChangeFeatureFlags(state.flags));

    const communityFlags = useCommunityFeatureFlagStore.getState().flags;
    if (communityFlags) {
      this.onChangeCommunityFlags(communityFlags);
    }
    this.unsubcribeCommunityFlags = useCommunityFeatureFlagStore.subscribe((state) => this.onChangeCommunityFlags(state.flags));

    const classroomFeatureFlags = useClassroomFeatureFlagStore.getState().flags;
    if (classroomFeatureFlags) {
      this.onChangeClassroomFeatureFlags(classroomFeatureFlags);
    }
    this.unsubscribeClassroomFeatureFlags = useClassroomFeatureFlagStore.subscribe((state) =>
      this.onChangeClassroomFeatureFlags(state.flags)
    );

    const filterLicenses = useFilterLicensesStore.getState();
    if (filterLicenses) {
      this.onChangeFilterLicenses(filterLicenses);
    }
    this.unsubscribeFilterLicenses = useFilterLicensesStore.subscribe((state) => this.onChangeFilterLicenses(state));

    const deviceOptions = useDeviceOptionsStore.getState().options;
    if (deviceOptions) {
      this.onChangeDeviceOptions(deviceOptions);
    }
    this.unsubscribeDeviceOptions = useDeviceOptionsStore.subscribe((state) => this.onChangeDeviceOptions(state.options));
  }

  /**
   * Remove the listeners when AppRoot unmounts
   */
  componentWillUnmount() {
    SessionStore.unlisten(this.onChange);
    DeviceStatusStore.unlisten(this.statusChange);

    if (this.unsubcribeFeatureFlags) {
      this.unsubcribeFeatureFlags();
      this.unsubcribeFeatureFlags = undefined;
    }

    if (this.unsubcribeCommunityFlags) {
      this.unsubcribeCommunityFlags();
      this.unsubcribeCommunityFlags = undefined;
    }

    if (this.unsubscribeClassroomFeatureFlags) {
      this.unsubscribeClassroomFeatureFlags();
      this.unsubscribeClassroomFeatureFlags = undefined;
    }

    if (this.unsubscribeFilterLicenses) {
      this.unsubscribeFilterLicenses();
      this.unsubscribeFilterLicenses = undefined;
    }

    if (this.unsubscribeDeviceOptions) {
      this.unsubscribeDeviceOptions();
      this.unsubscribeDeviceOptions = undefined;
    }
  }

  onChange = () => {
    if (!SessionStore.isAuthenticated()) {
      console.log("authentication is changing....");
      this.context.router.push("/login");
      return;
    }

    const selectedDevice = SessionStore.getSelectedDevice();
    if ((this.currentDevice ?? null) !== (selectedDevice ?? null)) {
      this.currentDevice = selectedDevice;

      if (selectedDevice) {
        void useFeatureFlagStore.getState().getOrFetch();
        void useCommunityFeatureFlagStore.getState().getOrFetch();
        void useClassroomFeatureFlagStore.getState().fetch();
        void useDeviceOptionsStore.getState().fetch();
        void useFilterLicensesStore.getState().fetch();
      } else {
        useFeatureFlagStore.getState().reset();
        useCommunityFeatureFlagStore.getState().reset();
        useClassroomFeatureFlagStore.getState().resetFlags();
        useDeviceOptionsStore.getState().reset();
        useFilterLicensesStore.getState().reset();
        useDevicePermissionsStore.getState().reset();
        useUserStore.getState().reset();

        // Reset all `useQuery` caches used by Reporting widgets and pages
        queryClient.clear();
      }
    }

    /* devices page check is done as when the devices page is loaded it will unselect the current selected device.
     * Due to the use of setTimeout to avoid AltJS dispatch issues, there is a race condition between when the timeout runs and the device is unselected,
     * the timeout may still run after the device has been unselected, and the fetches will then fail due to not having a device header.
     */
    if (SessionStore.getSelectedDevice() && this.context.router.location.pathname !== "/devices") {
      setTimeout(() => {
        // TODO: To be removed by https://familyzone.atlassian.net/browse/COMMUNITY-1214
        ConfigActions.fetch();
      }, 0);
    }
  };

  onChangeCommunityFlags = (flags) => {
    this.setState((prevState) => ({
      menuFeatureToggles: {
        ...prevState.menuFeatureToggles,
        "/config/device/community": flags?.enableCommunity,
        "/config/device/community/dashboard": flags?.enableCommunityDashboard,
        "/config/device/community/safety-net": flags?.enableDelegation,
      },
    }));
  };

  onChangeClassroomFeatureFlags = (flags) => {
    this.setState((prevState) => ({
      //nothing to do here for now leaving the handler
    }));
  };

  onChangeFilterLicenses = (licenses) => {
    this.setState((prevState) => ({
      menuFeatureToggles: {
        ...prevState.menuFeatureToggles,
        "/filtering/content-aware": licenses?.contentAwareEnabled,
      },
    }));
  };

  onChangeDeviceOptions = (deviceOptions) => {
    this.setState((prevState) => ({
      menuFeatureToggles: {
        ...prevState.menuFeatureToggles,
        // Licenses page is not visible on child appliances
        "/config/licenses": deviceOptions?.parent_id == null || deviceOptions?.parent_id === "",
      },
    }));
  };

  onChangeFeatureFlags = (flags) => {
    this.setState((prevState) => ({
      menuFeatureToggles: {
        ...prevState.menuFeatureToggles,
        "/cybersafety/unsafeapps": flags?.["display-mobile-apps"],
        "/reports": flags?.["connection-search-report"],
        "/reports/wellbeing": flags?.["show-student-wellbeing-menu-item"],
        "/reports/behaviour": flags?.["show-student-behaviour-menu-item"],
        "/reports/activity": flags?.["show-student-activity-menu-item"],
        "/reports/distracted": flags?.["show-distracted-student-menu-item"],
        "/reports/compliance": flags?.["show-activity-compliance-menu-item"],
        "/reports/applicationsusage": flags?.["show-applications-usage-menu-item"],
        "/filtering/cloudsafe": flags?.["show-cloudsafe-menu-item"],
        "/cybersafety/wellbeing": !flags?.["hide-red-flags"],
        "/config/device/network/httpsinspection": flags?.["show-https-inspection-menu-item"],
        "/config/device/authentication/clever": flags?.["show-clever-sync-menu-item"],
      },
    }));
  };

  render() {
    const { menuFeatureToggles } = this.state;

    const commonElements = (
      <>
        <Helmet>
          <title>{Layout.title()}</title>
        </Helmet>
        <AnnouncementBar key={"announcement-bar"} />
      </>
    );

    return (
      <ThemeProvider applyCSSReset>
        <Flex height="100%" flexDirection="column">
          {commonElements}
          <HeaderBar path={this.props.location.pathname} />
          <Flex overflow="hidden" height="100%">
            <Nav path={this.props.location.pathname} menuFeatureToggles={menuFeatureToggles} />
            {/*We got `ComponentWrapper` here and pass it to every InfiniteScroll because InfiniteScroll couldn't recognise the correct parent and trigger loadMore() function*/}
            <Flex overflow="auto" width="100%" id="ComponentWrapper">
              {this.props.children}
            </Flex>
          </Flex>
          <ChatWidget />
        </Flex>
      </ThemeProvider>
    );
  }
}

App.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default class AppRoot extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      selected_item: "config",
    };
    this.unsubAccountStore = null;
  }

  componentWillMount = () => {
    setTimeout(() => SessionActions.fetchAccountDevicePermissions(), 0);
    initI18n({ en_XX, en_uk });
  };

  componentDidMount() {
    changeLanguage(useAccountStore.getState().settings.language);
    this.unlistenBrowserHistory = browserHistory.listenBefore((location) => {
      let oldLocation = browserHistory.getCurrentLocation();
      if (
        (location.pathname !== oldLocation.pathname || process.env.NODE_ENV === "development") &&
        location.action !== "REPLACE" &&
        ["/surfwize", "/surfwize/device/network"].indexOf(location.pathname) < 0
      ) {
        setTimeout(GlobalDatePickerVisibilityActions.hideGlobalDatePicker, 0);
      }
    });
    this.unsubAccountStore = useAccountStore.subscribe((state) => changeLanguage(state.settings.language));
  }

  componentWillUnmount() {
    this.unlistenBrowserHistory();
    this.unsubAccountStore();
  }

  requireFeatureFlags(requiredFlags, redirectTo = "/config/device/forbidden") {
    // generate an onEnter function that ensures the specified requiredFlags are present
    return (nextState, replace, callback) => {
      this.requireDevice(nextState, replace);

      useFeatureFlagStore
        .getState()
        .getOrFetch()
        .then((featureFlags) => {
          if (!HasExpectedFlags(requiredFlags, featureFlags)) {
            replace({ pathname: redirectTo });
          }

          // proceed with navigation
          callback();
        });
    };
  }

  requireCommunityFlags(requiredFlags, redirectTo = "/config/device/forbidden") {
    // generate an onEnter function that ensures the specified requiredFlags are present
    return (nextState, replace, callback) => {
      this.requireDevice(nextState, replace);

      useCommunityFeatureFlagStore
        .getState()
        .getOrFetch()
        .then((communityFlags) => {
          if (!ValidateExpectedCommunityFlags(requiredFlags, communityFlags)) {
            replace({ pathname: redirectTo });
          }

          // proceed with navigation
          callback();
        });
    };
  }

  requireClassroomFeatureFlags(requiredFlags, redirectTo = "/config/device/forbidden") {
    // generate an onEnter function that ensures the specified requiredFlags are present
    return (nextState, replace, callback) => {
      this.requireDevice(nextState, replace);

      useClassroomFeatureFlagStore
        .getState()
        .fetch()
        .then((classroomFeatureFlags) => {
          if (!HasExpectedFlags(requiredFlags, classroomFeatureFlags)) {
            replace({ pathname: redirectTo });
          }

          // proceed with navigation
          callback();
        });
    };
  }

  requireFilterLicenses(requiredLicenses, redirectTo = "/config/device/forbidden") {
    // generate an onEnter function that ensures the specified requiredLicenses are present
    return (nextState, replace, callback) => {
      this.requireDevice(nextState, replace);

      useFilterLicensesStore
        .getState()
        .fetch()
        .then((filterLicenses) => {
          if (!filterLicenses.contentAwareEnabled) {
            replace({ pathname: redirectTo });
          }

          // proceed with navigation
          callback();
        });
    };
  }

  requireDevice(nextState, replace) {
    if (!SessionStore.hasDevice()) {
      replace({
        pathname: "/devices",
      });
    }
  }

  requireAuthentication(nextState, replace) {
    if (!SessionStore.isAuthenticated()) {
      replace({
        pathname: "/login",
      });
    }
  }

  onRouteUpdate = () => {
    window.scrollTo(0, 0);
    ApiActions.resetRequestCounter.defer();
  };

  deselectDevice = () => {
    try {
      if (SessionStore.getSelectedDevice()) {
        SessionActions.deselectDevice();
      }
    } catch (err) {
      console.warn(err);
    }
  };

  render() {
    let history = browserHistory;
    if (process.env.NODE_ENV === "development") {
      history = hashHistory;
    }

    return (
      <Router history={history} onUpdate={this.onRouteUpdate} render={(props) => <ReactRouterCompatProvider {...props} />}>
        <Route path="/login" component={Login} />
        <Route path="/login/google/callback" component={LoginGoogle} />
        <Route path="/login/register/welcome" component={LoginRegisterAwaitingVerification} />
        <Route path="/login/register/verify/:email/:code" component={LoginRegisterVerifyCode} />
        <Route path="/login/resetpassword" component={LoginResetPassword} />
        <Route path="/login/resetpassword/check" component={LoginResetPasswordCheckEmail} />
        <Route path="/login/resetpassword/confirm/:token" component={LoginResetPasswordConfirm} />
        <Route
          path="/config/device/userdb/guestsprinter/login/:login/name/:name/expiry/:expiry"
          component={Authorization(["owner", "surfwize_settings", "surfwize_guest_settings"])(GuestPrinter)}
          onEnter={this.requireDevice}
        />

        <Route component={App} onEnter={this.requireAuthentication}>
          <Redirect from="/" to="/devices" />
          <Route path="/devices" component={Devices} onEnter={this.deselectDevice} />
          {/* The route props that go into the below component is an object in the form of the param interface in the component */}
          <Route path="/device/:device_id/verify/:requester_email/:verify_code" component={AccessDeviceVerification} props={this.props} />
          <Route path="/account" component={Account} />
          <Route path="/devices/select/:deviceid" component={SelectDevice} />

          <Redirect from="/config" to="/config/device/dashboard" />
          <Route path="/config/device/forbidden" component={AccessDenied} onEnter={this.requireDevice} />
          <Route
            path="/config/device/dashboard"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"])(
              ConfigurationDashboard
            )}
            onEnter={this.requireDevice}
          />
          <Route path="/device/create" component={DeviceCreation} />

          <Route
            path="/config/device/calendar"
            component={Authorization(["owner", "classroom_admin", "community_admin", "surfwize_cloud_filter_admin", "surfwize_settings"])(
              SchoolCalendar
            )}
            onEnter={this.requireDevice}
          />

          {/* Licenses */}
          <Redirect from="/config/licenses" to="/config/licenses/filter" />
          <Route path="/config/licenses/filter" component={Authorization([])(FilterLicensesPage)} onEnter={this.requireDevice} />

          {/* Network Stuff */}
          <Redirect from="/config/device/network" to="/config/device/network/devices" />
          <Route
            path="/config/device/network/devices"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(Interfaces)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/network/devices/:interface"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(Interface)}
            onEnter={this.requireDevice}
          />

          <Route
            path="/config/device/network/routing"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(Routes)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/network/dns"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(Dns)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/network/highavailability"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(HighAvailability)}
            onEnter={this.requireDevice}
          />

          {/* Authentication Stuff */}
          <Redirect from="/config/device/authentication" to="/config/device/authentication/portals" />
          <Route
            path="/config/device/authentication/portals"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(Authentication)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/authentication/google"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(GoogleConfiguration)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/login/admin/google/callback"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(
              GoogleConfigurationLink
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/login/admin/google/customer/callback"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(
              GoogleCustomerConfigurationLink
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/authentication/azure"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(AzureConfiguration)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/authentication/sis-integration"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(SisIntegration)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/login/admin/azuread/callback"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(
              AzureConfigurationLink
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/authentication/clever"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(CleverConfiguration)}
            onEnter={this.requireDevice}
            featureFlag="show-clever-sync-menu-item"
          />
          <Route
            path="/config/login/admin/clever/callback"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(
              CleverConfigurationLink
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/authentication/ldap"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(LdapConfiguration)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/authentication/servers"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(LdapServers)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/authentication/wmi"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(WmiConfiguration)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/authentication/radius"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(RadiusConfiguration)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/authentication/syslog"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(SyslogConfiguration)}
            onEnter={this.requireDevice}
          />
          <Redirect from="/config/device/authentication/mergedidentity" to="/config/device/authentication/advanced" />
          <Route
            path="/config/device/authentication/advanced"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(
              AdvancedAuthenticationConfiguration
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/authentication/timeouts"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(Timeouts)}
            onEnter={this.requireDevice}
          />

          <Route
            path="/config/device/authentication/agent"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(AgentAuthentication)}
            onEnter={this.requireDevice}
          />

          <Route
            path="/config/device/authentication/timeouts/:timeout"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(Timeout)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/authentication/portals/:portal"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(AuthenticationManagePortal)}
            onEnter={this.requireDevice}
          />

          {/* Users and Groups */}
          <Redirect from="/config/device/userdb" to="/config/device/userdb/users" />
          <Route
            path="/config/device/userdb/users"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(Users)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/userdb/guests"
            component={Authorization([
              "owner",
              "surfwize_settings",
              "surfwize_guest_settings",
              "classroom_admin",
              "surfwize_cloud_filter_admin",
            ])(Guests)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/userdb/groups"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(GroupsTable)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/userdb/sessions"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(PermanentAssociations)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/userdb/exceptions"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(PermanentAssociationsExceptions)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/userdb/users/id/:id"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(ManageUserById)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/userdb/groups/:id"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(ManageGroup)}
            onEnter={this.requireDevice}
          />

          {/* Objects */}
          <Redirect from="/config/device/objects" to="/config/device/objects/pools" />
          <Route
            path="/config/device/objects/pools"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(ObjectPools)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/objects/pools/:pool"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(EditObjectPools)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/objects/securitygroups"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(SecurityGroups)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/objects/securitygroups/:securitygroup"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(SecurityGroup)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/objects/timeperiods"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(Periods)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/objects/timeperiods/:timeperiod"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "surfwize_cloud_filter_admin"])(Period)}
            onEnter={this.requireDevice}
          />

          {/* Classwize */}
          <Route path="/config/device/classwize" component={Authorization(PERMISSIONS.classwize)(Classwize)} onEnter={this.requireDevice} />
          <Route
            path="/config/device/classwize/classrooms"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"])(
              ClassroomsNew
            )}
            onEnter={this.requireDevice}
          />
          <Route path="/config/device/classwize/enabled-features" component={Authorization([])(ClassroomFeatureFlags)} />
          <Route
            path="/config/device/classwize/visibility-restrictions"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"])(
              ClassroomScreenVisibilityRestrictions
            )}
          />
          <Route path="/config/device/classwize/licencing" component={Authorization([])(ClasswizeLicencing)} onEnter={this.requireDevice} />
          <Route
            path="/config/device/classwize/classrooms/:id"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"])(
              Classroom
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/classwize/courses"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"])(
              Courses
            )}
            onEnter={this.requireDevice}
          />
          <Route path="/config/device/classwize/courses/efront" component={Authorization([])(EfrontConfig)} onEnter={this.requireDevice} />

          <Route
            path="/config/device/network/httpsinspection"
            component={Authorization(["owner", "surfwize_settings", "classroom_admin"])(ConfigurationMitm)}
            onEnter={this.requireDevice}
            featureFlag="show-https-inspection-menu-item"
          />
          <Route
            path="/config/device/cybersafety"
            component={Authorization(["owner", "surfwize_admin", "classroom_admin", "surfwize_cloud_filter_admin"])(CyberSafety)}
            onEnter={this.requireDevice}
          />

          {/* Community */}
          <Redirect from="/config/device/community" to="/config/device/community/dashboard" />
          <Route
            path="/config/device/community/dashboard"
            component={Authorization(["owner", "community_admin"])(CommunityDashboard)}
            onEnter={this.requireCommunityFlags(
              {
                enableCommunity: true,
                enableCommunityDashboard: true,
              },
              "/config/device/community/parent-management" // redirect to parent management in case community=enabled & dashboard=disabled
            ).bind(this)}
          />
          <Route
            path="/config/device/community/parent-management"
            component={Authorization(["owner", "community_admin"])(ParentManagement)}
            onEnter={this.requireCommunityFlags({
              enableCommunity: true,
            }).bind(this)}
          />
          <Route
            path="/config/device/community/parent-data-upload"
            component={Authorization(["owner", "community_admin"])(ParentDataUploadNew)}
            onEnter={this.requireCommunityFlags({
              enableCommunity: true,
            }).bind(this)}
          />
          <Route
            path="/config/device/community/configure"
            component={Authorization(["owner", "community_admin"])(CommunityConfiguration)}
            onEnter={this.requireCommunityFlags({
              enableCommunity: true,
            }).bind(this)}
          />

          <Route
            path="/config/device/walledgarden"
            component={Authorization(["owner", "surfwize_admin", "classroom_admin"])(WalledGarden)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/mobileagentconfiguration"
            component={Authorization(["owner", "surfwize_admin", "classroom_admin", "surfwize_cloud_filter_admin"])(
              MobileAgentConfiguration
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/config/device/agent-downloads"
            component={Authorization(["owner", "surfwize_admin", "classroom_admin", "surfwize_cloud_filter_admin"])(MobileAgentDownloads)}
          />
          <Route
            path="/config/device/blockpage"
            component={Authorization(["owner", "surfwize_admin", "classroom_admin", "surfwize_cloud_filter_admin"])(BlockPage)}
            onEnter={this.requireDevice}
          />

          {/* Commented out for now until we have another feature lab feature */}
          {/* <Route
            path="/featurelab"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "surfwize_filtering",
              "surfwize_settings",
              "surfwize_guest_settings",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
              "edgewize",
              "community_admin",
            ])(FeatureLab)}
            onEnter={this.requireDevice}
          /> */}

          {/* Edgewize Routes */}
          <Redirect path="/edgewize" to="/edgewize/status" />
          <Route path="/edgewize/status" component={Authorization(["edgewize"])(EdgewizeDashboard)} onEnter={this.requireDevice} />

          <Route path="/edgewize/device/firewall" component={Authorization(["edgewize"])(Firewall)} onEnter={this.requireDevice} />

          <Redirect from="/edgewize/device/wan" to="/edgewize/device/wan/general" />
          <Route path="/edgewize/device/wan/general" component={Authorization(["edgewize"])(Wan)} onEnter={this.requireDevice} />
          <Route
            path="/edgewize/device/wan/portforwarding"
            component={Authorization(["edgewize"])(WanPortForwarding)}
            onEnter={this.requireDevice}
          />
          <Route path="/edgewize/device/wan/onetoone" component={Authorization(["edgewize"])(OneToOneNat)} onEnter={this.requireDevice} />
          <Route path="/edgewize/device/wan/manytoone" component={Authorization(["edgewize"])(ManyToOneNat)} onEnter={this.requireDevice} />
          <Route
            path="/edgewize/device/wan/exceptions"
            component={Authorization(["edgewize"])(NatExceptions)}
            onEnter={this.requireDevice}
          />

          <Route path="/edgewize/device/mdns" component={Authorization(["edgewize"])(Mdns)} onEnter={this.requireDevice} />
          <Route path="/edgewize/device/mdns/:mdns_id" component={Authorization(["edgewize"])(MdnsEdit)} onEnter={this.requireDevice} />

          <Route path="/edgewize/device/vpn" component={Authorization(["edgewize"])(GatewayToGatewayVpns)} onEnter={this.requireDevice} />
          <Route
            path="/edgewize/device/vpn/:vpn_id"
            component={Authorization(["edgewize"])(GatewayToGatewayVpn)}
            onEnter={this.requireDevice}
          />
          <Route path="/edgewize/device/clientvpn" component={Authorization(["edgewize"])(ClientVpn)} onEnter={this.requireDevice} />

          <Redirect from="/edgewize/device/qos" to="/edgewize/device/qos/ratelimiter" />
          <Route
            path="/edgewize/device/qos/ratelimiter"
            component={Authorization(["edgewize"])(RatelimiterQos)}
            onEnter={this.requireDevice}
          />
          <Route path="/edgewize/device/qos/priority" component={Authorization(["edgewize"])(PriorityQos)} onEnter={this.requireDevice} />

          {/* Cyber Safety Routes */}
          <Redirect path="/cybersafety" to="/cybersafety/wellbeing" />

          <Route
            path="/cybersafety/search"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingSearch)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/cybersafety/search/:expression"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingSearchUsersNew)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/cybersafety/search/user/:user"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingSearchUserAudit)}
            onEnter={this.requireDevice}
          />

          <Route
            path="/cybersafety/search/user/:user/:search"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingSearchUserAudit)}
            onEnter={this.requireDevice}
          />

          <Route
            path="/cybersafety/blocks"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingBlocked)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/cybersafety/blocks/violations/:policyId"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingViolations)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/cybersafety/blocks/violations/:policyId/user/:user"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingViolationUser)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/cybersafety/blocks/violations/:policyId/user/:user/day/:day"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingViolationUserDay)}
            onEnter={this.requireDevice}
          />

          <Route
            path="/cybersafety/wellbeing"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingWelfare)}
            onEnter={this.requireFeatureFlags({ "hide-red-flags": false }, "/cybersafety/search").bind(this)}
          />

          <Route
            path="/cybersafety/video"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(VideoDashboard)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/cybersafety/video/:videoid"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(VideoDetails)}
            onEnter={this.requireDevice}
          />

          {/* Surfwize Routes */}
          <Redirect path="/surfwize" to="/surfwize/dashboard" />
          <Route
            path="/surfwize/dashboard"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(Dashboard)}
            onEnter={this.requireDevice}
          />

          <Redirect from="/surfwize/device/status" to="/surfwize/device/status/hosts" />
          <Route
            path="/surfwize/device/status/connections"
            component={Authorization(["owner", "surfwize_admin", "surfwize_reporting", "classroom_admin", "classroom_ed-tech"])(
              ActiveConnections
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/device/status/connections/:search"
            component={Authorization(["owner", "surfwize_admin", "surfwize_reporting", "classroom_admin", "classroom_ed-tech"])(
              ActiveConnections
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/device/status/hosts"
            component={Authorization(["owner", "surfwize_admin", "surfwize_reporting", "classroom_admin", "classroom_ed-tech"])(
              ActiveHosts
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/device/status/hosts/:user"
            component={Authorization(["owner", "surfwize_admin", "surfwize_reporting", "classroom_admin", "classroom_ed-tech"])(
              ActiveHosts
            )}
            props={this.props}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/device/status/events"
            component={Authorization(["owner", "surfwize_admin", "surfwize_reporting", "classroom_admin", "classroom_ed-tech"])(EventsNew)}
            onEnter={this.requireDevice}
          />

          <Redirect path="/surfwize/reporting" to="/surfwize/reporting/types" />
          <Route
            path="/surfwize/reporting/types"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingTopTypes)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/reporting/type/:type/users"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingTopTypeUsers)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/reporting/type/:type/user/:user"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingTypeUserNew)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/reporting/website/:website/users"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingTopWebsiteUsers)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/reporting/website/:website/user/:user"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingWebsiteUser)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/reporting/users"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingTopUsersNew)}
            onEnter={this.requireDevice}
          />
          <Redirect path="/surfwize/device/network" to="/surfwize/device/network/hosts" />
          <Route
            path="/surfwize/device/network/hosts"
            component={Authorization(["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"])(
              ReportingTopHosts
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/device/network/destip"
            component={Authorization(["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"])(
              ReportingTopDestinationIps
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/device/network/destip/:destIp/users"
            component={Authorization(["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"])(
              ReportingDestinationIpUsers
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/device/network/destPort/:destPort/users"
            component={Authorization(["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"])(
              ReportingDestinationPortUsers
            )}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/device/network/destports"
            component={Authorization(["owner", "surfwize_admin", "classroom_admin", "classroom_ed-tech", "surfwize_cloud_filter_admin"])(
              ReportingTopDestinationPorts
            )}
            onEnter={this.requireDevice}
          />

          <Route
            path="/surfwize/reporting/users/:user"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(UserDashboardIndex)}
            props={this.props}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/reporting/users/:user/timeline"
            component={Authorization([
              "owner",
              "surfwize_admin",
              "surfwize_reporting",
              "classroom_admin",
              "classroom_ed-tech",
              "surfwize_cloud_filter_admin",
            ])(ReportingUserTimeline)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/surfwize/reporting/users/:user/journey"
            component={Authorization(["owner", "surfwize_admin", "surfwize_reporting", "classroom_admin", "classroom_ed-tech"])(
              UserJourney
            )}
            onEnter={this.requireDevice}
          />

          <Redirect path="/reports" to="/reports/advanced" />
          <Route
            path="/reports/advanced"
            component={Authorization(["owner", "surfwize_admin"])(LookerPageWrapper)}
            onEnter={this.requireDevice}
            featureFlag="connection-search-report"
            apiEndpoint="/looker/generate-embed-url"
          />
          <Route
            path="/reports/wellbeing"
            component={Authorization(["owner", "surfwize_admin"])(LookerPageWrapper)}
            onEnter={this.requireDevice}
            featureFlag="show-student-wellbeing-menu-item"
            apiEndpoint="/looker/generate-wellbeing-embed-url"
          />
          <Route
            path="/reports/behaviour"
            component={Authorization(["owner", "surfwize_admin"])(LookerPageWrapper)}
            onEnter={this.requireDevice}
            featureFlag="show-student-behaviour-menu-item"
            apiEndpoint="/looker/generate-behaviour-embed-url"
          />
          <Route
            path="/reports/activity"
            component={Authorization(["owner", "surfwize_admin"])(LookerPageWrapper)}
            onEnter={this.requireDevice}
            featureFlag="show-student-activity-menu-item"
            apiEndpoint="/looker/generate-activity-embed-url"
          />
          <Route
            path="/reports/distracted"
            component={Authorization(["owner", "surfwize_admin"])(LookerPageWrapper)}
            onEnter={this.requireDevice}
            featureFlag="show-distracted-student-menu-item"
            apiEndpoint="/looker/generate-distracted-embed-url"
          />
          <Route
            path="/reports/compliance"
            component={Authorization(["owner", "surfwize_admin"])(LookerPageWrapper)}
            onEnter={this.requireDevice}
            featureFlag="show-activity-compliance-menu-item"
            apiEndpoint="/looker/generate-activity-compliance-embed-url"
          />
          <Route
            path="/reports/applicationsusage"
            component={Authorization(["owner", "surfwize_admin"])(LookerPageWrapper)}
            onEnter={this.requireDevice}
            featureFlag="show-applications-usage-menu-item"
            apiEndpoint="/looker/generate-application-usage-embed-url"
          />
          <Redirect path="/filtering" to="/filtering/policies" />
          <Route
            path="/filtering/content-aware"
            component={Authorization(["owner", "surfwize_admin", "surfwize_filtering", "surfwize_cloud_filter_admin"])(
              ContentAwareConfigPage
            )}
            onEnter={this.requireFilterLicenses(
              {
                contentAwareEnabled: true,
              },
              "/filtering"
            ).bind(this)}
          />
          <Route
            path="/filtering/policies"
            component={Authorization(["owner", "surfwize_admin", "surfwize_filtering", "surfwize_cloud_filter_admin"])(WebFilteringNew)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/filtering/modifications"
            component={Authorization(["owner", "surfwize_admin", "surfwize_filtering", "surfwize_cloud_filter_admin"])(ContentModPage)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/filtering/dns"
            component={Authorization(["surfwize_cloud_dns_filter_admin"])(DnsFiltering)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/filtering/cloudsafe"
            component={Authorization(["surfwize_cloud_dns_filter_admin"])(CloudSafe)}
            onEnter={this.requireDevice}
            featureFlag="show-cloudsafe-menu-item"
          />
          <Route
            path="/filtering/outside-school-hours"
            component={Authorization(["owner", "surfwize_cloud_filter_admin"])(OutsideSchoolHours)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/filtering/safesearch"
            component={Authorization(["owner", "surfwize_admin", "surfwize_filtering", "surfwize_cloud_filter_admin"])(SafeSearch)}
            onEnter={this.requireDevice}
          />
          {/* Bypass stuff */}
          <Redirect from="/filtering/bypass" to="/filtering/bypass/available" />
          <Route
            path="/filtering/bypass/available"
            component={Authorization(["owner", "surfwize_settings", "surfwize_filtering", "surfwize_cloud_filter_admin"])(BypassAvailable)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/filtering/bypass/active"
            component={Authorization(["owner", "surfwize_settings", "surfwize_filtering", "surfwize_cloud_filter_admin"])(
              BypassCodesActive
            )}
            onEnter={this.requireDevice}
          />

          <Redirect path="/filtering/alerting" to="/filtering/alerting/reports" />
          <Route
            path="/filtering/alerting/reports"
            component={Authorization(["owner", "surfwize_admin", "surfwize_reporting", "surfwize_cloud_filter_admin"])(ReportsAndAlerts)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/filtering/alerting/custom"
            component={Authorization(["owner", "surfwize_admin", "surfwize_reporting", "surfwize_cloud_filter_admin"])(ReportsAndAlerts)}
            onEnter={this.requireDevice}
          />

          {/* Manage device options */}
          <Redirect path="/managedevice" to="/managedevice/settings/device" />
          <Route
            path="/managedevice/settings/device"
            component={Authorization(["owner", "surfwize_cloud_filter_admin"])(ManageDeviceSettings)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/managedevice/settings/updates"
            component={Authorization(["owner"])(ManageDeviceUpdates)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/managedevice/settings/permissions"
            component={Authorization(["owner", "surfwize_cloud_filter_admin"])(ManageDevicePermissions)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/managedevice/settings/snapshots"
            component={Authorization(["owner", "surfwize_cloud_filter_admin"])(ManageDeviceSnapshots)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/managedevice/settings/audit(/:tabName)"
            component={Authorization(["owner", "surfwize_cloud_filter_admin", "community_admin"])(ConfigAudit)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/managedevice/settings/feature-flags"
            component={Authorization([])(ManageDeviceFeatureFlags)}
            onEnter={this.requireDevice}
          />

          <Redirect path="/managedevice/settings/diagnostics" to="/managedevice/settings/diagnostics/alarms" />
          <Route
            path="/managedevice/settings/diagnostics/alarms"
            component={Authorization(["owner", "surfwize_cloud_filter_admin"])(DiagnosticsAlarms)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/managedevice/settings/diagnostics/logs"
            component={Authorization(["owner"])(DiagnosticsLogfiles)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/managedevice/settings/diagnostics/coredumps"
            component={Authorization(["owner"])(DiagnosticsCoredumps)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/managedevice/settings/diagnostics/metrics"
            component={Authorization(["owner"])(DiagnosticsMetrics)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/managedevice/settings/diagnostics/advancedmetrics"
            component={Authorization(["owner"])(DiagnosticsMetricsAdvanced)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/managedevice/settings/diagnostics/interfacemetrics"
            component={Authorization([])(DiagnosticsInterfaceMetrics)} // Load for Support Admin only
            onEnter={this.requireDevice}
          />
          <Route
            path="/managedevice/settings/diagnostics/advancedconfiguration"
            component={Authorization(["owner"])(DiagnosticsAdvancedConfiguration)}
            onEnter={this.requireDevice}
          />
          <Route
            path="/managedevice/settings/diagnostics/netconsole"
            component={Authorization(["owner"])(Netconsole)}
            onEnter={this.requireDevice}
          />
        </Route>
      </Router>
    );
  }
}
