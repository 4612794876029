import React, { useCallback, useEffect, useState } from "react";
import { Modal, ModalBody, TextInput, useToast, useTranslation } from "@familyzone/component-library";
import { isValidEmail } from "../../utils/Validation";
import { Guardian, Student } from "../../types/Community";
import { useGuardianStore } from "../../storez/GuardianStore";
import { ResponseError } from "../../types/Api";

interface AddParentModalProps {
  show: boolean;
  student: Student;
  guardians?: Guardian[];
  handleHide: () => void;
  handleSuccess: (guardian: Guardian) => void;
}

const AddParentModal: React.FC<AddParentModalProps> = ({ show, student, guardians, handleHide, handleSuccess }) => {
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [firstNameChanged, setFirstNameChanged] = useState(false);
  const [lastNameChanged, setLastNameChanged] = useState(false);
  const [emailChanged, setEmailChanged] = useState(false);

  const [invalidFirstNameReason, setInvalidFirstNameReason] = useState<string | undefined>(undefined);
  const [invalidLastNameReason, setInvalidLastNameReason] = useState<string | undefined>(undefined);
  const [invalidEmailReason, setInvalidEmailReason] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!show) {
      clearFields();
    }
  }, [show]);

  const validInput: () => boolean = useCallback(() => {
    return (invalidFirstNameReason || invalidLastNameReason || invalidEmailReason) === undefined;
  }, [invalidFirstNameReason, invalidLastNameReason, invalidEmailReason]);

  const requiredFieldsChanged: () => boolean = useCallback(() => {
    return firstNameChanged && lastNameChanged && emailChanged;
  }, [firstNameChanged, lastNameChanged, emailChanged]);

  const validateFirstName = (firstName: string) => {
    let firstname_error = undefined;
    if (firstName.length === 0) {
      firstname_error = "First name is required";
    }
    setInvalidFirstNameReason(firstname_error);
  };

  const validateEmail = (email: string) => {
    let email_error = undefined;
    if (email.length === 0) {
      email_error = "Email is required";
    } else if (!isValidEmail(email)) {
      email_error = "Please enter a valid email address";
    } else if (guardians?.some((g) => g.email.trim() === email.trim())) {
      email_error = "The student already has a parent with given email";
    }
    setInvalidEmailReason(email_error);
  };

  const validateLastName = (lastName: string) => {
    let lastname_error = undefined;
    if (lastName.length === 0) {
      lastname_error = "Last name is required";
    }
    setInvalidLastNameReason(lastname_error);
  };

  const handleChangeFirstName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFirstNameChanged(true);
    setFirstName(event.target.value);
    validateFirstName(event.target.value);
  };

  const handleChangeMiddleName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setMiddleName(event.target.value);
  };

  const handleChangeLastName = (event: React.ChangeEvent<HTMLInputElement>) => {
    setLastNameChanged(true);
    setLastName(event.target.value);
    validateLastName(event.target.value);
  };

  const handleChangeEmail = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailChanged(true);
    setEmail(event.target.value);
    validateEmail(event.target.value);
  };

  const clearFields = () => {
    setFirstName("");
    setMiddleName("");
    setLastName("");
    setEmail("");
    setFirstNameChanged(false);
    setLastNameChanged(false);
    setEmailChanged(false);
    setInvalidFirstNameReason("");
    setInvalidLastNameReason("");
    setInvalidEmailReason("");
  };

  const handleSubmit = () => {
    setLoading(true);

    const guardianToAdd = {
      email: email,
      firstName: firstName,
      lastName: lastName,
      middleName: middleName,
      sourceType: "LOCAL",
    };

    void useGuardianStore
      .getState()
      .addUserGuardian(student.username, guardianToAdd)
      .then(
        (added) => {
          handleSuccess(added);
          successToast({
            title: t("Parent added successfully"),
            description:
              t("Parent") +
              ` ${added.firstName} ${added.lastName} ` +
              t("has been successfully added to student") +
              ` ${student.firstName} ${student.lastName}`,
            isClosable: true,
          });
        },
        (err: ResponseError) => {
          if (err.status === 400 && err.message.includes("address")) {
            setInvalidEmailReason("Please enter a valid email address");
          } else {
            errorToast({
              title: t("Please try again"),
              description: t(err.message),
              isClosable: true,
            });
          }
        }
      )
      .finally(() => setLoading(false));
  };

  return (
    <Modal
      headerText={t("Add A New Parent")}
      isOpen={show}
      onClose={handleHide}
      size="sm"
      primaryCTALabel={t("Save and Add Parent")}
      primaryCTADisabled={loading || !requiredFieldsChanged() || !validInput()}
      onPrimaryCTAClick={handleSubmit}
      secondaryCTALabel={t("Cancel")}
      secondaryCTADisabled={loading}
      onSecondaryCTAClick={handleHide}
    >
      <ModalBody>
        <form className="modal-form">
          <TextInput
            label="First Name"
            placeholder=""
            name="first-name"
            value={firstName}
            onChange={handleChangeFirstName}
            isRequired={true}
            isDisabled={loading}
            errorMessage={invalidFirstNameReason}
          />
          <TextInput
            label="Middle Name"
            placeholder=""
            name="middle-name"
            value={middleName}
            onChange={handleChangeMiddleName}
            isDisabled={loading}
          />
          <TextInput
            label="Last Name"
            placeholder=""
            name="last-name"
            value={lastName}
            onChange={handleChangeLastName}
            isRequired={true}
            isDisabled={loading}
            errorMessage={invalidLastNameReason}
          />
          <TextInput
            label="Email Address"
            placeholder=""
            name="email"
            value={email}
            onChange={handleChangeEmail}
            isRequired={true}
            isDisabled={loading}
            errorMessage={invalidEmailReason}
          />
        </form>
      </ModalBody>
    </Modal>
  );
};

export default AddParentModal;
