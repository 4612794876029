import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/form-control";
import { Flex, Tooltip } from "@chakra-ui/react";
import {
  Box,
  Button,
  Checkbox,
  Icon,
  InlineNotification,
  Link,
  Modal,
  ModalBody,
  Radio,
  RadioGroup,
  Select,
  Spinner,
  Text,
  useToast,
  useTranslation,
} from "@familyzone/component-library";
import { Modifier } from "@popperjs/core";
import React, { ChangeEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link as ReactLink } from "react-router";
import { WEEKLY_CAMPAIGN_KEY } from "../../constants/CommunityConstants";
import { CampaignConfigStore, useCampaignConfigStore } from "../../storez/CampaignConfigStore";
import { CommunityConfigStore, SaveOptions, useCommunityConfigStore } from "../../storez/CommunityConfigStore";
import { CommunitySafetyNetStore, useCommunitySafetyNetStore } from "../../storez/SafetyNetStore";
import { useSchoolCalendarStore } from "../../storez/SchoolCalendarStore";
import { ResponseError } from "../../types/Api";
import { CalendarRule } from "../../types/Calendar";
import { CampaignConfig, CampaignConfigEquals, CampaignConfigPlaceholder } from "../../types/Campaign";
import {
  CommunityConfig,
  CommunityConfigEquals,
  DataSharingScope,
  DelegationSchoolReportingScope,
  ExclusionConfig,
  Group,
  InclusionConfig,
  MapDelegationSchoolReportingScopeToOption,
  User,
} from "../../types/Community";
import SectionHeading from "../header/components/SectionHeading";
import ManageExclusionsModal from "../modals/ManageExclusionsModal";
import SaveConflictErrorModal from "../modals/SaveConflictErrorModal";
import CardBasedPage from "../templates/CardBasedPage";
import SafetyNetModal from "./CommunityConfig/SafetyNetModal";
import { isCommunityAuditsAccessible } from "../debug/ConfigAudit/AuditUtils";
import { useDevicePermissionsStore } from "../../storez/DevicePermissionsStore";
import { AuditScope } from "../../types/Audit";
import { useOutsideSchoolHoursReportingStore } from "../../storez/OutsideSchoolHoursReportingStore";

/**
 * Popper (i.e., the library managing Chakra Tooltips) transform to override tooltip arrow placement
 * to be exactly aligned with respective checkboxes. By default, the position is calculated based on
 * the reference element size which for labelled checkboxes puts the arrow somewhere over the text.
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const arrowPlacementTransform: Modifier<"arrowPlacementTransform", any> = {
  name: "arrowPlacementTransform",
  enabled: true,
  phase: "beforeWrite",
  requires: ["computeStyles"],
  fn: ({ state }) => {
    state.styles.arrow.transform = "translate(16px, 0px)";
  },
};

const CommunityConfiguration: React.FC = () => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const title = t("Configure Community");

  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("Community"), url: "/config/device/community", isActive: false },
    { title: t("Configure Community"), isActive: true },
  ];

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [config, setConfig] = useState<CommunityConfig>({});
  const [showConflictModal, setShowConflictModal] = useState<boolean>(false);
  const [showUserJourneyConfirmModal, setShowUserJourneyConfirmModal] = useState<boolean>(false);
  const [showUserJourneyExclusionModal, setShowUserJourneyExclusionModal] = useState<boolean>(false);
  const [showWeeklyCampaignExclusionModal, setShowWeeklyCampaignExclusionModal] = useState<boolean>(false);
  const [showDelegationConfirmModal, setShowDelegationConfirmModal] = useState<boolean>(false);
  const [showUserJourneyAndDelegationConfirmModal, setShowUserJourneyAndDelegationConfirmModal] = useState<boolean>(false);
  const [showDelegationExclusionModal, setShowDelegationExclusionModal] = useState<boolean>(false);
  const [showDelegationInclusionModal, setShowDelegationInclusionModal] = useState<boolean>(false);
  const [showSafetyNetModal, setShowSafetyNetModal] = useState<boolean>(false);
  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});

  const conflictOverrideFn = useRef<() => void>();

  const [storedCommunityConfig, fetchCommunityConfig, saveCommunityConfig, resetCommunityStore] = useCommunityConfigStore(
    useCallback((state: CommunityConfigStore) => [state.config, state.fetch, state.save, state.reset] as const, [])
  );

  const [campaignConfig, setCampaignConfig] = useState<CampaignConfig>(CampaignConfigPlaceholder);
  const [storedCampaignConfig, fetchCampaignConfig, saveCampaignConfig, resetCampaignStore] = useCampaignConfigStore(
    useCallback((state: CampaignConfigStore) => [state.config, state.fetch, state.save, state.reset] as const, [])
  );

  const [getSafetyNetPolicies, createRecommendedSafetyNet] = useCommunitySafetyNetStore(
    useCallback((state: CommunitySafetyNetStore) => [state.getActivePolicies, state.createRecommended] as const, [])
  );

  const [reportingConfig, fetchReportingConfig] = useOutsideSchoolHoursReportingStore(
    useCallback((state) => [state.config, state.fetch] as const, [])
  );

  const [permissions, supportAdmin] = useDevicePermissionsStore(
    useCallback((state) => [state.permissions, state.supportAdmin] as const, [])
  );

  const showErrorToast = (msg: string) => {
    errorToast({
      title: t("Please try again"),
      description: t(msg),
      isClosable: true,
    });
  };

  const showSaveSuccessToast = (msg?: string): void => {
    successToast({
      title: t("Update successful"),
      description: msg ? t(msg) : t("Community configuration has been successfully updated"),
      isClosable: true,
    });
  };

  const fetchSchoolReportingOptions = () => {
    return [
      MapDelegationSchoolReportingScopeToOption(DelegationSchoolReportingScope.ALL),
      MapDelegationSchoolReportingScopeToOption(DelegationSchoolReportingScope.BLOCKED),
      MapDelegationSchoolReportingScopeToOption(DelegationSchoolReportingScope.NONE),
    ];
  };

  const [hasSchoolTimeRule, setHasSchoolTimeRule] = useState<boolean | undefined>(undefined);
  const [isSchoolTimeRuleFetchingFailed, setIsSchoolTimeRuleFetchingFailed] = useState<boolean>(false);

  const [fetchSchoolTime] = useSchoolCalendarStore(useCallback((state) => [state.fetch] as const, []));

  useEffect(() => {
    Promise.all([fetchCommunityConfig(), fetchCampaignConfig(WEEKLY_CAMPAIGN_KEY), fetchReportingConfig()]).then(
      ([config, campaignConfig]) => {
        setConfig(config);
        setCampaignConfig(campaignConfig);
        setLoading(false);
      },
      (err: ResponseError) => {
        showErrorToast(err.message);
      }
    );

    return () => {
      resetCommunityStore();
      resetCampaignStore();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchCommunityConfig, fetchCampaignConfig, fetchReportingConfig]);

  useEffect(() => {
    void fetchSchoolTime()
      .then((rule: CalendarRule | undefined) => {
        setHasSchoolTimeRule(!!rule);
      })
      .catch(() => {
        setIsSchoolTimeRuleFetchingFailed(true);
      });
  }, [fetchSchoolTime]);

  const validate = (): boolean => {
    const errors: Record<string, string> = {};
    const studentUsage = config.studentUsage;
    const pauseInternetConfig = config.pauseInternet;
    const delegationConfig = config.policyDelegation;

    if (studentUsage?.shareStudentUsage) {
      if (!studentUsage.journeySharingScope) {
        errors["journeySharingScope"] = t("Data Sharing Time must be specified");
      }
      if (!studentUsage.shareAllowedActivity && !studentUsage.shareBlockedActivity) {
        errors["shareAllowedActivity"] = t("Either Share Allowed Activity or Share Blocked Activity must be selected");
        errors["shareBlockedActivity"] = t("Either Share Blocked Activity or Share Allowed Activity must be selected");
      }
    }

    // validation is passed only when the rule was fetched successfully (not undefined) and exists (not false)
    if (hasSchoolTimeRule !== true) {
      if (studentUsage?.shareStudentUsage) {
        errors["shareStudentUsage"] = t("Share Student Usage and Insights cannot be enabled until the School Calendar is setup");
      }

      if (pauseInternetConfig?.allowPauseInternet) {
        errors["allowPauseInternet"] = t("Allow Pause Internet cannot be enabled until the School Calendar is setup");
      }

      if (delegationConfig?.allowPolicyDelegation) {
        errors["allowPolicyDelegation"] = t("Parental Controls cannot be enabled until the School Calendar is setup");
      }
    }

    if (delegationConfig?.allowPolicyDelegation) {
      if (delegationConfig.schoolReportingScope === undefined) {
        errors["delegationSchoolReportingScope"] = t("School Reporting Option must be configured");
      }
    }

    setFieldErrors(errors);
    return Object.keys(errors).length === 0;
  };

  const handleCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const keys = event.target.id.split(".");
    const newConfig = { ...config };

    if ("studentUsage" === keys[0]) {
      const changedObj = { ...newConfig.studentUsage };

      const propName = keys[1] as keyof typeof changedObj;
      switch (propName) {
        case "shareStudentUsage":
        case "shareUserJourney":
        case "shareAllowedActivity":
        case "shareBlockedActivity":
        case "shareSearchTerms":
        case "shareAccessedVideos":
          changedObj[propName] = event.target.checked;
          break;
      }

      if (!changedObj["shareAllowedActivity"] && !changedObj["shareBlockedActivity"]) {
        changedObj["shareSearchTerms"] = false;
        changedObj["shareAccessedVideos"] = false;
      }

      newConfig.studentUsage = changedObj;
    } else if ("pauseInternet" === keys[0]) {
      const changedObj = { ...newConfig.pauseInternet };
      if ("allowPauseInternet" === keys[1]) {
        changedObj.allowPauseInternet = event.target.checked;
      }
      newConfig.pauseInternet = changedObj;
    } else if ("policyDelegation" === keys[0]) {
      const changedObj = { ...newConfig.policyDelegation };
      if ("allowPolicyDelegation" === keys[1]) {
        changedObj.allowPolicyDelegation = event.target.checked;
        if (event.target.checked && storedCommunityConfig?.policyDelegation?.allowPolicyDelegation) {
          // when user simply uncheck and re-check (without saving), restore the currently persisted values
          changedObj.applySafetyNet = storedCommunityConfig?.policyDelegation?.applySafetyNet;
          changedObj.applySafeSearch = storedCommunityConfig?.policyDelegation?.applySafeSearch;
          changedObj.schoolReportingScope = storedCommunityConfig?.policyDelegation?.schoolReportingScope;
        } else {
          changedObj.applySafetyNet = false;
          changedObj.applySafeSearch = false;
          changedObj.schoolReportingScope = undefined;
        }

        if (event.target.checked && !reportingConfig?.includeActivity) {
          // when outside school hours reporting is disabled, user will not be able
          // to select the reporting scope; therefore, we default to 'None' here
          changedObj.schoolReportingScope = DelegationSchoolReportingScope.NONE;
        }
      }
      newConfig.policyDelegation = changedObj;
    }

    setConfig(newConfig);
  };

  const handleWeeklyCampaignCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    setCampaignConfig({ ...campaignConfig, enabled: event.target.checked });
  };

  const handleTogglePause247 = () => {
    const newConfig = { ...config };

    newConfig.pauseInternet = {
      ...newConfig.pauseInternet,
      allowPause247: !(config.pauseInternet?.allowPause247 as boolean),
    };

    setConfig(newConfig);
  };

  const handleToggleSafetyNet = () => {
    const newConfig = { ...config };

    newConfig.policyDelegation = {
      ...newConfig.policyDelegation,
      applySafetyNet: !!!config.policyDelegation?.applySafetyNet,
    };

    if (newConfig.policyDelegation.applySafetyNet && storedCommunityConfig?.policyDelegation?.applySafetyNet) {
      // when user simply switches between options without saving, restore the currently persisted values
      newConfig.policyDelegation.applySafeSearch = storedCommunityConfig?.policyDelegation?.applySafeSearch;
    } else if (newConfig.policyDelegation.applySafetyNet) {
      newConfig.policyDelegation.applySafeSearch = true;
    } else {
      newConfig.policyDelegation.applySafeSearch = false;
    }

    setConfig(newConfig);
  };

  const handleJourneyScopeChange = (value: string): void => {
    const newConfig = { ...config };

    newConfig.studentUsage = {
      ...newConfig.studentUsage,
      journeySharingScope: value as DataSharingScope,
    };

    setConfig(newConfig);
  };

  const handleDelegationApplySafeSearchCheckboxChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const newConfig = { ...config };

    newConfig.policyDelegation = {
      ...newConfig.policyDelegation,
      applySafeSearch: event.target.checked,
    };

    setConfig(newConfig);
  };

  const handleDelegationSchoolReportingScopeChange = (selected: { value: string; scope?: DelegationSchoolReportingScope } | null): void => {
    const newConfig = { ...config };

    newConfig.policyDelegation = {
      ...newConfig.policyDelegation,
      schoolReportingScope: selected?.scope,
    };

    setConfig(newConfig);
  };

  const doSave = (communityConfig: CommunityConfig, campaignConfig: CampaignConfig, options?: SaveOptions) => {
    setSaving(true);

    const promises = [];

    if (!CommunityConfigEquals(communityConfig, storedCommunityConfig)) {
      promises.push(
        saveCommunityConfig(communityConfig, options).then((saved) => {
          setConfig(saved);
        })
      );
    }

    if (!CampaignConfigEquals(campaignConfig, storedCampaignConfig)) {
      promises.push(
        saveCampaignConfig(campaignConfig).then((saved) => {
          setCampaignConfig(saved);
        })
      );
    }

    Promise.all(promises)
      .then(() => {
        showSaveSuccessToast();
        hideAllModals();
      })
      .catch((err: ResponseError) => {
        if (err.status === 409) {
          conflictOverrideFn.current = doSave.bind(this, config, campaignConfig, { overrideVersionConflict: true });
          setShowConflictModal(true);
        } else {
          showErrorToast(err.message);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleSave = (_event: ChangeEvent<HTMLInputElement>): void => {
    if (validate()) {
      const dataSharingNewlyEnabled = !storedCommunityConfig?.studentUsage?.shareUserJourney && config.studentUsage?.shareUserJourney;
      const delegationNewlyEnabled =
        !storedCommunityConfig?.policyDelegation?.allowPolicyDelegation && config.policyDelegation?.allowPolicyDelegation;
      const delegationNewlyDisabled =
        storedCommunityConfig?.policyDelegation?.allowPolicyDelegation && !config.policyDelegation?.allowPolicyDelegation;

      if (dataSharingNewlyEnabled && delegationNewlyDisabled) {
        setShowUserJourneyAndDelegationConfirmModal(true);
        return;
      }

      if (delegationNewlyDisabled) {
        setShowDelegationConfirmModal(true);
        return;
      }

      // if user is enabling 'Share Student Timeline' option, then show them 'Data Sharing Guide' dialog first
      if (dataSharingNewlyEnabled) {
        setShowUserJourneyConfirmModal(true);
        return;
      }

      if (delegationNewlyEnabled && config.policyDelegation?.applySafetyNet) {
        setSaving(true);
        void getSafetyNetPolicies().then(
          (policies) => {
            if (policies.length === 0) {
              void createRecommendedSafetyNet().then(
                () => {
                  void doSave(config, campaignConfig);
                },
                (err: ResponseError) => {
                  errorToast({
                    title: t("Please try again"),
                    description: t(err.message),
                    isClosable: true,
                  });
                  setSaving(false);
                }
              );
            } else {
              void doSave(config, campaignConfig);
            }
          },
          (err: ResponseError) => {
            showErrorToast(err.message);
            setSaving(false);
          }
        );
        return;
      }

      void doSave(config, campaignConfig);
    }
  };

  const saveExclusions = (key: "studentUsage" | "policyDelegation", changes: ExclusionConfig | InclusionConfig, options?: SaveOptions) => {
    setSaving(true);

    // clone the currently *persisted* config
    const newConfig = { ...storedCommunityConfig };

    // apply exclusion and/or inclusion changes
    newConfig[key] = {
      ...newConfig[key],
      ...changes,
    };

    void saveCommunityConfig(newConfig, options)
      .then((savedConfig) => {
        // merge the saved changes with the view changes
        const viewConfig = { ...config, version: savedConfig.version };

        if (key === "policyDelegation") {
          viewConfig[key] = {
            ...viewConfig[key],
            excludeGroups: savedConfig[key]?.excludeGroups,
            excludeUsers: savedConfig[key]?.excludeUsers,
            includeGroups: savedConfig[key]?.includeGroups,
            includeUsers: savedConfig[key]?.includeUsers,
          };
        } else {
          viewConfig[key] = {
            ...viewConfig[key],
            excludeGroups: savedConfig[key]?.excludeGroups,
            excludeUsers: savedConfig[key]?.excludeUsers,
          };
        }

        setConfig(viewConfig);

        if (key === "policyDelegation") {
          showSaveSuccessToast("Parental Control exclusions have been successfully saved");
        } else if (key === "studentUsage") {
          showSaveSuccessToast("Student Timeline exclusions have been successfully saved");
        }

        setShowConflictModal(false);
        setShowUserJourneyExclusionModal(false);
        setShowDelegationExclusionModal(false);
        setShowDelegationInclusionModal(false);
      })
      .catch((err: ResponseError) => {
        if (err.status === 409) {
          conflictOverrideFn.current = saveExclusions.bind(this, key, changes, { overrideVersionConflict: true });
          setShowConflictModal(true);
        } else {
          showErrorToast(err.message);
        }
      })
      .finally(() => setSaving(false));
  };

  const saveCampaignExclusions = (changes: ExclusionConfig, options?: SaveOptions) => {
    if (!storedCampaignConfig) throw Error("bad view state: campaign config is undefined");

    setSaving(true);

    // clone the currently *persisted* config
    const newConfig = { ...storedCampaignConfig };

    // apply exclusion changes
    newConfig.criteria = {
      ...newConfig.criteria,
      ...changes,
    };

    void saveCampaignConfig(newConfig, options)
      .then((savedConfig) => {
        // merge the saved changes with the view changes
        const viewConfig = { ...campaignConfig, version: savedConfig.version };

        viewConfig.criteria = {
          ...viewConfig.criteria,
          excludeGroups: savedConfig.criteria.excludeGroups,
          excludeUsers: savedConfig.criteria.excludeUsers,
        };

        setCampaignConfig(viewConfig);
        showSaveSuccessToast("Weekly Report exclusions have been successfully saved");
        setShowConflictModal(false);
        setShowWeeklyCampaignExclusionModal(false);
      })
      .catch((err: ResponseError) => {
        if (err.status === 409) {
          conflictOverrideFn.current = saveCampaignExclusions.bind(this, changes, { overrideVersionConflict: true });
          setShowConflictModal(true);
        } else {
          showErrorToast(err.message);
        }
      })
      .finally(() => setSaving(false));
  };

  const handleCloseConflictModal = (): void => {
    setShowConflictModal(false);
    setSaving(false);
    setLoading(false);
  };

  const handleConfirmSave = (): void => {
    setSaving(true);
    void doSave(config, campaignConfig);
  };

  const handleOverrideSaveConflict = (): void => {
    if (conflictOverrideFn.current) {
      conflictOverrideFn.current();
    }
  };

  const handleReloadPage = (): void => {
    setLoading(true);

    Promise.all([fetchCommunityConfig(), fetchCampaignConfig(WEEKLY_CAMPAIGN_KEY)])
      .then(([config, campaignConfig]) => {
        setConfig(config);
        setCampaignConfig(campaignConfig);
        setLoading(false);
        hideAllModals();
      })
      .catch((err: ResponseError) => {
        showErrorToast(err.message);
      });
  };

  const hideAllModals = (): void => {
    setShowConflictModal(false);
    setShowUserJourneyExclusionModal(false);
    setShowWeeklyCampaignExclusionModal(false);
    setShowDelegationExclusionModal(false);
    setShowDelegationInclusionModal(false);
  };

  const handleOpenUserJourneyExclusions = () => {
    setShowUserJourneyExclusionModal(true);
  };

  const handleCloseUserJourneyExclusions = () => {
    setShowUserJourneyExclusionModal(false);
  };

  const handleSaveUserJourneyExclusions = (excludeGroups: Group[], excludeUsers: User[]) => {
    saveExclusions("studentUsage", { excludeGroups, excludeUsers });
  };

  const handleOpenWeeklyCampaignExclusions = () => {
    setShowWeeklyCampaignExclusionModal(true);
  };

  const handleCloseWeeklyCampaignExclusions = () => {
    setShowWeeklyCampaignExclusionModal(false);
  };

  const handleSaveWeeklyCampaignExclusions = (excludeGroups: Group[], excludeUsers: User[]) => {
    saveCampaignExclusions({ excludeGroups, excludeUsers });
  };

  const handleOpenSafetyNetModal = () => {
    setShowSafetyNetModal(true);
  };

  const handleCloseSafetyNetModal = () => {
    setShowSafetyNetModal(false);
  };

  const handleOpenDelegationExclusions = () => {
    setShowDelegationExclusionModal(true);
  };

  const handleCloseDelegationExclusions = () => {
    setShowDelegationExclusionModal(false);
  };

  const handleOpenDelegationInclusions = () => {
    setShowDelegationInclusionModal(true);
  };

  const handleCloseDelegationInclusions = () => {
    setShowDelegationInclusionModal(false);
  };

  const handleSaveDelegationExclusions = (excludeGroups: Group[], excludeUsers: User[]) => {
    saveExclusions("policyDelegation", { excludeGroups, excludeUsers });
  };

  const handleSaveDelegationInclusions = (includeGroups: Group[], includeUsers: User[]) => {
    saveExclusions("policyDelegation", { includeGroups, includeUsers });
  };

  const hasChanges = !CommunityConfigEquals(config, storedCommunityConfig) || !CampaignConfigEquals(campaignConfig, storedCampaignConfig);

  const canAccessCommunity = useMemo(() => {
    return isCommunityAuditsAccessible(permissions, supportAdmin);
  }, [permissions, supportAdmin]);

  const viewHistoryLink = canAccessCommunity ? (
    <Link
      fontSize="md"
      fontWeight="bold"
      as={ReactLink}
      to={"/managedevice/settings/audit/community?scope=" + AuditScope.COMMUNITY + "&scope=" + AuditScope.SAFETY_NET}
    >
      {t("View History")}
    </Link>
  ) : (
    <></>
  );

  return (
    <>
      <CardBasedPage title={title} tools={viewHistoryLink} breadcrumbs={breadcrumbs}>
        <>
          {isSchoolTimeRuleFetchingFailed && (
            <InlineNotification
              status="error"
              notificationDescription={<Box color="text.title">{t("Error loading School Calendar. Refresh to try again.")}</Box>}
            />
          )}
        </>

        <>
          {hasSchoolTimeRule === false && (
            <Box margin="15px 24px 0 24px">
              <InlineNotification
                status="error"
                notificationDescription={
                  <Box color="text.title">
                    A calendar has not been configured which is required for Community Functionality. Go to{" "}
                    <Link as={ReactLink} to="/config/device/calendar">
                      {t("Configuration | Calendar")}
                    </Link>{" "}
                    to set up the calendar.
                  </Box>
                }
              />
            </Box>
          )}
        </>

        <Box minHeight="200px" p="sp24" position="relative">
          {loading ? (
            <Flex
              position="absolute"
              top={0}
              left={0}
              bottom={0}
              right={0}
              justifyContent="center"
              alignItems="center"
              borderRadius="md"
              backgroundColor="rgba(255, 255, 255, 0.3)"
            >
              <Spinner size="md" />
            </Flex>
          ) : (
            <></>
          )}

          <SectionHeading title={t("Student Usage and Insights")} />

          <Box px="sp12" py="sp16">
            <>
              <FormControl
                mb="sp16"
                isDisabled={
                  loading ||
                  saving ||
                  config.studentUsage?.shareUserJourney ||
                  (config.featureFlags?.enableWeeklyCampaign && campaignConfig.enabled)
                }
                isInvalid={!!fieldErrors.shareStudentUsage}
              >
                {/* Flex parent and Box child are required for tooltip to be positioned correctly */}
                <Flex>
                  <Tooltip
                    variant="dark"
                    placement="top-start"
                    hasArrow={true}
                    maxWidth="336px"
                    isDisabled={!config.studentUsage?.shareUserJourney && !campaignConfig.enabled}
                    label={t(
                      "This option is required to enable Share Student Timeline or Send Weekly Reports. To disable this option, both those two checkboxes must be disabled."
                    )}
                    offset={[-16, 8]}
                    modifiers={[arrowPlacementTransform]}
                  >
                    <Box>
                      <Checkbox
                        id="studentUsage.shareStudentUsage"
                        isChecked={config.studentUsage?.shareStudentUsage}
                        isDisabled={
                          loading ||
                          saving ||
                          config.studentUsage?.shareUserJourney ||
                          (config.featureFlags?.enableWeeklyCampaign && campaignConfig.enabled)
                        }
                        onChange={handleCheckboxChange}
                      >
                        {t("Share Student Usage and Insights")}
                      </Checkbox>
                    </Box>
                  </Tooltip>
                </Flex>

                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Shares aggregate data from student usage with parents.")} {t("Find out more")}{" "}
                  <Link
                    href="https://help.linewize.com/hc/en-gb/articles/6609030805532-Sharing-student-data-with-parents-using-Community"
                    isExternal={true}
                  >
                    {t("here")}
                  </Link>
                  .
                  {config.studentUsage?.shareStudentUsage && (
                    <FormErrorMessage fontWeight="regular">{fieldErrors.shareStudentUsage}</FormErrorMessage>
                  )}
                </FormHelperText>
              </FormControl>

              {config.studentUsage?.shareStudentUsage && (
                <Box ml="sp24" maxWidth="576px">
                  <FormControl mb="sp16" isRequired={true} isInvalid={!!fieldErrors.journeySharingScope}>
                    <FormLabel fontWeight="medium">{t("Data Sharing Time")}</FormLabel>
                    <FormHelperText color="neutrals.200">
                      {t("Select during which time student data usage should be captured.")}
                    </FormHelperText>
                    <FormErrorMessage fontWeight="regular">{fieldErrors.journeySharingScope}</FormErrorMessage>
                  </FormControl>

                  <RadioGroup direction="column" value={config.studentUsage?.journeySharingScope} onChange={handleJourneyScopeChange}>
                    <FormControl isDisabled={loading || saving} position="relative">
                      <Box display="flex" flexDirection="column" alignItems="left">
                        <Radio isChecked={config.studentUsage?.journeySharingScope === DataSharingScope.ALL} value={DataSharingScope.ALL}>
                          <FormLabel mr="sp16">{t("All Time")}</FormLabel>
                        </Radio>
                        <Box ml="22px">
                          <FormHelperText color="neutrals.200">
                            {t("Student data usage will be captured regardless of when it occurs, both during and outside school time.")}
                          </FormHelperText>
                        </Box>
                      </Box>
                    </FormControl>
                    <FormControl isDisabled={loading || saving} position="relative">
                      <Radio
                        isChecked={config.studentUsage?.journeySharingScope === DataSharingScope.SCHOOL_TIME}
                        value={DataSharingScope.SCHOOL_TIME}
                        isDisabled={loading || saving}
                      >
                        {t("School Time Only")}
                      </Radio>
                      <FormHelperText ml="22px" color="neutrals.200">
                        {t("Student data usage will only be captured during school time.")}
                      </FormHelperText>
                    </FormControl>
                    <FormControl isDisabled={loading || saving} position="relative">
                      <Radio
                        isChecked={config.studentUsage?.journeySharingScope === DataSharingScope.OFF_SCHOOL}
                        value={DataSharingScope.OFF_SCHOOL}
                        isDisabled={loading || saving}
                      >
                        {t("Outside School Time Only")}
                      </Radio>
                      <FormHelperText ml="22px" color="neutrals.200">
                        {t("Student data will only be captured outside school time.")}
                      </FormHelperText>
                    </FormControl>
                  </RadioGroup>

                  <FormControl mt="sp16" mb="sp16" isRequired={true}>
                    <FormLabel fontWeight="medium">{t("Type of Activity to Share")}</FormLabel>
                    <FormHelperText color="neutrals.200">
                      {t("Select one or both activity types to allow data to be shared.")}
                    </FormHelperText>
                  </FormControl>

                  <FormControl mb="sp16" isDisabled={loading || saving} isInvalid={!!fieldErrors.shareAllowedActivity}>
                    <Checkbox
                      id="studentUsage.shareAllowedActivity"
                      isChecked={config.studentUsage?.shareAllowedActivity}
                      isDisabled={loading || saving}
                      onChange={handleCheckboxChange}
                    >
                      {t("Share Allowed Activity")}
                    </Checkbox>
                    <FormErrorMessage fontWeight="regular">{fieldErrors.shareAllowedActivity}</FormErrorMessage>
                  </FormControl>

                  <FormControl mb="sp24" isDisabled={loading || saving} isInvalid={!!fieldErrors.shareBlockedActivity}>
                    <Checkbox
                      id="studentUsage.shareBlockedActivity"
                      isChecked={config.studentUsage?.shareBlockedActivity}
                      isDisabled={loading || saving}
                      onChange={handleCheckboxChange}
                    >
                      {t("Share Blocked Activity")}
                    </Checkbox>
                    <FormErrorMessage fontWeight="regular">{fieldErrors.shareBlockedActivity}</FormErrorMessage>
                  </FormControl>
                </Box>
              )}
            </>

            <>
              <FormControl mb="sp16" isDisabled={loading || saving || !config.studentUsage?.shareStudentUsage}>
                <Flex>
                  <Tooltip
                    variant="dark"
                    placement="top-start"
                    hasArrow={true}
                    maxWidth="304px"
                    isDisabled={config.studentUsage?.shareStudentUsage}
                    label={t("To enable Share Student Timeline, the Student Usage and Insights option needs to be enabled first.")}
                    offset={[-16, 8]}
                    modifiers={[arrowPlacementTransform]}
                  >
                    <Box>
                      <Checkbox
                        id="studentUsage.shareUserJourney"
                        isChecked={config.studentUsage?.shareUserJourney}
                        isDisabled={loading || saving || !config.studentUsage?.shareStudentUsage}
                        onChange={handleCheckboxChange}
                      >
                        {t("Share Student Timeline")}
                      </Checkbox>
                    </Box>
                  </Tooltip>
                </Flex>

                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Shares a timeline of student usage data with parents.")} {t("Find out more")}{" "}
                  <Link
                    href="https://help.linewize.com/hc/en-gb/articles/8725380350876-Share-student-timeline-with-parents-using-Community"
                    isExternal={true}
                  >
                    {t("here")}
                  </Link>
                  .
                </FormHelperText>
              </FormControl>

              {config.studentUsage?.shareUserJourney && (
                <Box ml="sp24" mb="sp24" maxWidth="576px">
                  <InlineNotification
                    status="warning"
                    notificationDescription={
                      <Box color="text.title">
                        {t("This allows parents to view a timeline of usage data for the connected student.")}
                        <br />
                        {t("All connected students will have this data shared, unless they are part of an exclusion.")}
                        <br />
                        {t("To understand the student privacy implications, please read the ")}
                        <Link
                          href="https://help.linewize.com/hc/en-gb/articles/8634675014044-School-Community-Data-Sharing-Guide"
                          isExternal={true}
                        >
                          {t("Data Sharing Guide")}
                        </Link>
                        .
                      </Box>
                    }
                  />

                  <FormControl mt="sp16" mb="sp16">
                    <FormLabel fontWeight="medium">{t("Additional Data Sharing Options")}</FormLabel>
                  </FormControl>

                  <FormControl
                    mb="sp16"
                    isDisabled={
                      loading || saving || (!config.studentUsage?.shareAllowedActivity && !config.studentUsage?.shareBlockedActivity)
                    }
                  >
                    <Flex>
                      <Tooltip
                        variant="dark"
                        placement="top-start"
                        hasArrow={true}
                        maxWidth="304px"
                        isDisabled={config.studentUsage?.shareAllowedActivity || config.studentUsage?.shareBlockedActivity}
                        label={t('The "Type of Activity to Share" must be selected to enable this option.')}
                        offset={[-16, 8]}
                        modifiers={[arrowPlacementTransform]}
                      >
                        <Box>
                          <Checkbox
                            id="studentUsage.shareSearchTerms"
                            isChecked={config.studentUsage?.shareSearchTerms}
                            isDisabled={
                              loading ||
                              saving ||
                              (!config.studentUsage?.shareAllowedActivity && !config.studentUsage?.shareBlockedActivity)
                            }
                            onChange={handleCheckboxChange}
                          >
                            {t("Share Search Terms")}
                          </Checkbox>
                        </Box>
                      </Tooltip>
                    </Flex>
                  </FormControl>

                  <FormControl
                    mb="sp16"
                    isDisabled={
                      loading || saving || (!config.studentUsage?.shareAllowedActivity && !config.studentUsage?.shareBlockedActivity)
                    }
                  >
                    <Flex>
                      <Tooltip
                        variant="dark"
                        placement="top-start"
                        hasArrow={true}
                        maxWidth="304px"
                        isDisabled={config.studentUsage?.shareAllowedActivity || config.studentUsage?.shareBlockedActivity}
                        label={t('The "Type of Activity to Share" must be selected to enable this option.')}
                        offset={[-16, 8]}
                        modifiers={[arrowPlacementTransform]}
                      >
                        <Box>
                          <Checkbox
                            id="studentUsage.shareAccessedVideos"
                            isChecked={config.studentUsage?.shareAccessedVideos}
                            isDisabled={
                              loading ||
                              saving ||
                              (!config.studentUsage?.shareAllowedActivity && !config.studentUsage?.shareBlockedActivity)
                            }
                            onChange={handleCheckboxChange}
                          >
                            {t("Share Videos Accessed")}
                          </Checkbox>
                        </Box>
                      </Tooltip>
                    </Flex>
                  </FormControl>

                  <Flex>
                    <Tooltip
                      variant="dark"
                      placement="top-start"
                      hasArrow={true}
                      maxWidth="320px"
                      isDisabled={storedCommunityConfig?.studentUsage?.shareUserJourney}
                      label={t("Save changes to configure user and group exclusions.")}
                      offset={[-16, 8]}
                    >
                      <Box>
                        <Button
                          onClick={handleOpenUserJourneyExclusions}
                          variant="primary"
                          disabled={loading || saving || !storedCommunityConfig?.studentUsage?.shareUserJourney}
                          aria-label={t("Manage Timeline Exclusions")}
                        >
                          {t("Manage Timeline Exclusions")}
                        </Button>
                      </Box>
                    </Tooltip>
                  </Flex>

                  <ManageExclusionsModal
                    headerText={t("Manage Timeline Exclusions")}
                    isOpen={showUserJourneyExclusionModal}
                    onSave={handleSaveUserJourneyExclusions}
                    onClose={handleCloseUserJourneyExclusions}
                    groups={config.studentUsage?.excludeGroups || []}
                    users={config.studentUsage?.excludeUsers || []}
                  />
                </Box>
              )}
            </>

            {config.featureFlags?.enableWeeklyCampaign && (
              <>
                <FormControl
                  mb="sp16"
                  isDisabled={loading || saving || !config.studentUsage?.shareStudentUsage || campaignConfig.version < 0}
                >
                  <Flex>
                    <Tooltip
                      variant="dark"
                      placement="top-start"
                      hasArrow={true}
                      maxWidth="304px"
                      isDisabled={config.studentUsage?.shareStudentUsage}
                      label={t("To enable Send Weekly Reports, the Student Usage and Insights option needs to be enabled first.")}
                      offset={[-16, 8]}
                      modifiers={[arrowPlacementTransform]}
                    >
                      <Box>
                        <Checkbox
                          id="studentUsage.sendWeeklyReports"
                          isChecked={campaignConfig.enabled}
                          isDisabled={loading || saving || !config.studentUsage?.shareStudentUsage || campaignConfig.version < 0}
                          onChange={handleWeeklyCampaignCheckboxChange}
                        >
                          {t("Send Weekly Reports")}
                        </Checkbox>
                      </Box>
                    </Tooltip>
                  </Flex>

                  <FormHelperText ml="sp24" color="neutrals.200">
                    {t("Sends an invitation and weekly email with student usage data to parents who have not signed up to Community.")}
                  </FormHelperText>
                </FormControl>

                {campaignConfig.enabled && (
                  <Box ml="sp24" mb="sp16" maxWidth="576px">
                    <Box>
                      <Button
                        mb="sp16"
                        onClick={handleOpenWeeklyCampaignExclusions}
                        variant="primary"
                        disabled={loading || saving}
                        aria-label="Manage Weekly Report Exclusions"
                      >
                        {t("Manage Weekly Report Exclusions")}
                      </Button>
                    </Box>

                    <ManageExclusionsModal
                      headerText={t("Manage Weekly Report Exclusions")}
                      isOpen={showWeeklyCampaignExclusionModal}
                      onSave={handleSaveWeeklyCampaignExclusions}
                      onClose={handleCloseWeeklyCampaignExclusions}
                      groups={campaignConfig.criteria.excludeGroups || []}
                      users={campaignConfig.criteria.excludeUsers || []}
                    />
                  </Box>
                )}
              </>
            )}
          </Box>

          <Box>
            <SectionHeading title={t("Pause Internet")} />

            <Box px="sp12" py="sp16">
              <FormControl mb="sp16" isDisabled={loading || saving} isInvalid={!!fieldErrors.allowPauseInternet}>
                <Checkbox
                  id="pauseInternet.allowPauseInternet"
                  isChecked={!!config.pauseInternet?.allowPauseInternet}
                  isDisabled={loading || saving}
                  onChange={handleCheckboxChange}
                >
                  {t("Allow Pause Internet")}
                </Checkbox>

                <FormHelperText ml="sp24" color="neutrals.200">
                  {t("Allow parents to pause internet on assigned school devices.")} {t("Find out more")}{" "}
                  <Link href="https://help.linewize.com/hc/en-gb/articles/6610242033692-Pause-Internet-FAQ-for-schools" isExternal={true}>
                    {t("here")}
                  </Link>
                  .
                  {config.pauseInternet?.allowPauseInternet && (
                    <FormErrorMessage fontWeight="regular">{fieldErrors.allowPauseInternet}</FormErrorMessage>
                  )}
                </FormHelperText>
              </FormControl>

              {config.pauseInternet?.allowPauseInternet && (
                <Box ml="sp24">
                  <RadioGroup direction="column">
                    <FormControl isDisabled={loading || saving} position="relative">
                      <Box display="flex" flexDirection="column" alignItems="left">
                        <Radio isChecked={!(config.pauseInternet?.allowPause247 as boolean)} onChange={handleTogglePause247}>
                          <FormLabel mr="sp16">{t("Allow Pause Outside School Hours")}</FormLabel>
                        </Radio>
                        <Box ml="22px">
                          <FormHelperText color="neutrals.200">
                            {t("Internet cannot be paused during school hours. You can configure the school hours ")}
                            <Link as={ReactLink} to="/config/device/calendar">
                              {t("here")}
                            </Link>
                            .
                          </FormHelperText>
                        </Box>
                      </Box>
                    </FormControl>
                    <FormControl mb="sp16" isDisabled={loading || saving} position="relative">
                      <Radio
                        isChecked={config.pauseInternet?.allowPause247 as boolean}
                        isDisabled={loading || saving}
                        onChange={handleTogglePause247}
                      >
                        {t("Allow Pause 24/7")}
                      </Radio>
                      <FormHelperText ml="22px" color="neutrals.200">
                        {t("When enabled, internet can be paused during school hours.")}
                      </FormHelperText>
                    </FormControl>
                  </RadioGroup>
                </Box>
              )}
            </Box>
          </Box>

          {config.featureFlags?.enableDelegation && (
            <Box>
              <SectionHeading title={t("Configure options for parents to manage school devices outside of school hours")} />
              <Box px="sp12" py="sp16">
                <FormControl mb="sp16" isDisabled={loading || saving} isInvalid={!!fieldErrors.allowPolicyDelegation}>
                  <Checkbox
                    id="policyDelegation.allowPolicyDelegation"
                    isChecked={!!config.policyDelegation?.allowPolicyDelegation}
                    isDisabled={loading || saving}
                    onChange={handleCheckboxChange}
                  >
                    {t("Parental Controls")}
                  </Checkbox>
                  <FormHelperText ml="sp24" color="neutrals.200">
                    <Text>{t("Allow parents to apply their rules to school managed devices outside of school hours.")}</Text>
                    {config.policyDelegation?.allowPolicyDelegation && (
                      <FormErrorMessage fontWeight="regular">{fieldErrors.allowPolicyDelegation}</FormErrorMessage>
                    )}
                  </FormHelperText>
                </FormControl>

                {config.policyDelegation?.allowPolicyDelegation && (
                  <Box ml="sp24" mb="sp16">
                    <RadioGroup direction="column">
                      <FormControl isDisabled={loading || saving} position="relative">
                        <Box display="flex" flexDirection="column" alignItems="left">
                          <Radio
                            isChecked={!config.policyDelegation.applySafetyNet}
                            isDisabled={loading || saving}
                            onChange={handleToggleSafetyNet}
                          >
                            {t("Use Current School Policies")}
                          </Radio>
                          <Box ml="22px">
                            <FormHelperText color="neutrals.200">
                              {t("When enabled, school filtering policies apply when student is under parental control.")}{" "}
                              {t("Parents can setup additional rules in the Qustodio app.")}
                            </FormHelperText>
                          </Box>
                        </Box>
                      </FormControl>
                      <FormControl isDisabled={loading || saving} position="relative">
                        <Radio
                          isChecked={!!config.policyDelegation?.applySafetyNet}
                          isDisabled={loading || saving}
                          onChange={handleToggleSafetyNet}
                        >
                          {t("Use a Safety Net")}
                        </Radio>
                        <FormHelperText ml="22px" color="neutrals.200">
                          {t("When enabled, only certain categories are blocked when student is under parental control.")}{" "}
                          {t("Parents can setup additional rules in the Qustodio app.")}
                        </FormHelperText>
                      </FormControl>
                    </RadioGroup>
                  </Box>
                )}

                {config.policyDelegation?.applySafetyNet && (
                  <Box ml="sp24">
                    <FormControl mb="sp16">
                      <FormLabel fontWeight="medium">
                        <Flex direction="row" alignItems="center">
                          <Flex>{t("Safety Net")}</Flex>
                          <Flex>
                            <Tooltip
                              variant="dark"
                              placement="top-start"
                              hasArrow={true}
                              maxWidth="336px"
                              label={t(
                                "The School SafetyNet is a minimum set of policies that apply and cannot be overridden by parents on school managed devices outside of school hours."
                              )}
                              offset={[2, 8]}
                            >
                              <Flex>
                                <Icon ml="sp8" variant="solid" color="brand.400" fontSize="lg" icon="fa-info-circle" />
                              </Flex>
                            </Tooltip>
                          </Flex>
                        </Flex>
                      </FormLabel>
                      <FormHelperText color="neutrals.200" mb="sp8">
                        {t("Configure minimum policies that cannot be overridden when parents are managing school devices.")}
                      </FormHelperText>
                      <Button variant="primary" onClick={handleOpenSafetyNetModal} disabled={loading || saving}>
                        {t("School Safety Net")}
                      </Button>
                    </FormControl>

                    <FormControl mb="sp16" isRequired={true} isDisabled={loading || saving}>
                      <Checkbox
                        id="policyDelegation.applySafeSearch"
                        isChecked={!!config.policyDelegation?.applySafeSearch}
                        isDisabled={loading || saving}
                        onChange={handleDelegationApplySafeSearchCheckboxChange}
                      >
                        {t("Safe Search")}
                      </Checkbox>
                      <FormHelperText ml="sp24" color="neutrals.200">
                        {t("Enforce safe search on school devices outside of school hours.")}
                      </FormHelperText>
                    </FormControl>
                  </Box>
                )}

                {config.policyDelegation?.allowPolicyDelegation && (
                  <Box ml="sp24">
                    <FormControl
                      mb="sp16"
                      isRequired={true}
                      isInvalid={!!fieldErrors.delegationSchoolReportingScope}
                      isDisabled={loading || saving || !reportingConfig?.includeActivity}
                    >
                      <FormLabel fontWeight="medium">{t("Outside School Hours Activity")}</FormLabel>
                      <FormHelperText color="neutrals.200" mb="sp8">
                        {t("Select what device activity the school can see when parents are managing school devices.")}
                      </FormHelperText>
                      <Select
                        id="policyDelegation.schoolReportingScope"
                        data-testid="schoolReportingScope"
                        aria-label={t("Select Outside School Hours Activity")}
                        items={fetchSchoolReportingOptions()}
                        selectedItem={
                          config.policyDelegation?.schoolReportingScope
                            ? MapDelegationSchoolReportingScopeToOption(config.policyDelegation.schoolReportingScope)
                            : undefined
                        }
                        disabled={loading || saving || !reportingConfig?.includeActivity}
                        errorMessage={fieldErrors.delegationSchoolReportingScope}
                        onChange={handleDelegationSchoolReportingScopeChange}
                      />

                      {!reportingConfig?.includeActivity && (
                        <Box mt="sp8" width="fit-content">
                          <InlineNotification
                            status="info"
                            notificationDescription={
                              <Box pr="sp8" color="text.title">
                                {t("To change this option, outside school hours activity reporting must be enabled in")}{" "}
                                <Link as={ReactLink} to="/filtering/outside-school-hours">
                                  {t("Filtering | Outside School Hours")}
                                </Link>
                                {"."}
                              </Box>
                            }
                          />
                        </Box>
                      )}
                    </FormControl>

                    {!config.featureFlags?.enableDelegationByInclusion && (
                      <Box>
                        <FormControl mb="sp16">
                          <Button
                            onClick={handleOpenDelegationExclusions}
                            variant="primary"
                            disabled={loading || saving || config.policyDelegation?.schoolReportingScope === undefined}
                            aria-label="Manage Parental Controls Exclusions"
                          >
                            {t("Manage Exclusions")}
                          </Button>
                        </FormControl>

                        <ManageExclusionsModal
                          headerText={t("Manage Parental Control Exclusions")}
                          isOpen={showDelegationExclusionModal}
                          onSave={handleSaveDelegationExclusions}
                          onClose={handleCloseDelegationExclusions}
                          groups={config.policyDelegation?.excludeGroups || []}
                          users={config.policyDelegation?.excludeUsers || []}
                        />
                      </Box>
                    )}

                    {config.featureFlags?.enableDelegationByInclusion && (
                      <Box>
                        <FormControl mb="sp16">
                          <Button
                            onClick={handleOpenDelegationInclusions}
                            variant="primary"
                            disabled={loading || saving || config.policyDelegation?.schoolReportingScope === undefined}
                            aria-label="Manage Parental Controls Inclusions"
                          >
                            {t("Manage Inclusions")}
                          </Button>
                        </FormControl>

                        <ManageExclusionsModal
                          headerText={t("Manage Parental Control Inclusions")}
                          isInclusionModal={true}
                          isOpen={showDelegationInclusionModal}
                          onSave={handleSaveDelegationInclusions}
                          onClose={handleCloseDelegationInclusions}
                          groups={config.policyDelegation?.includeGroups || []}
                          users={config.policyDelegation?.includeUsers || []}
                        />
                      </Box>
                    )}
                  </Box>
                )}
              </Box>
            </Box>
          )}

          <Button mt="sp12" variant="primary" disabled={loading || saving || !hasChanges} onClick={handleSave}>
            {saving ? t("Saving..") : hasChanges ? t("Save") : t("Saved")}
          </Button>
        </Box>
      </CardBasedPage>
      <Modal
        size="md"
        isOpen={showUserJourneyConfirmModal}
        variant="warning"
        headerText={t("Enable Share Student Timeline")}
        primaryCTALabel={t("Enable Share Student Timeline")}
        onPrimaryCTAClick={() => {
          setShowUserJourneyConfirmModal(false);
          handleConfirmSave();
        }}
        onSecondaryCTAClick={() => {
          setShowUserJourneyConfirmModal(false);
        }}
        onClose={() => {
          setShowUserJourneyConfirmModal(false);
        }}
      >
        <ModalBody>
          <Text mb="sp8" fontFamily="paragraphs">
            {t(
              "This functionality allows parents to view all the timeline of usage data for the connected student. To understand the student privacy implications, read the"
            )}{" "}
            <Link href="https://help.linewize.com/hc/en-gb/articles/8634675014044-School-Community-Data-Sharing-Guide" isExternal={true}>
              {t("Data Sharing Guide")}
            </Link>
            .
          </Text>
          <Text mb="sp8" fontFamily="paragraphs">
            {t("Are you sure you want to enable the Share Student Timeline configuration?")}
          </Text>
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={showDelegationConfirmModal}
        variant="warning"
        headerText={t("You've disabled Parental Controls")}
        primaryCTALabel={t("Confirm")}
        onPrimaryCTAClick={() => {
          setShowDelegationConfirmModal(false);
          handleConfirmSave();
        }}
        secondaryCTALabel={t("Cancel")}
        onSecondaryCTAClick={() => {
          setShowDelegationConfirmModal(false);
        }}
        onClose={() => {
          setShowDelegationConfirmModal(false);
        }}
      >
        <ModalBody>
          <Text mb="sp16" color="text.title">
            {t("You have disabled the management of school devices to parents after school.")}{" "}
            {t("Parents will lose the ability to manage school devices and they will be notified.")}
          </Text>
          <Text color="text.title">{t("Are you sure you want to proceed?")}</Text>
        </ModalBody>
      </Modal>

      <Modal
        size="md"
        isOpen={showUserJourneyAndDelegationConfirmModal}
        variant="warning"
        headerText={t("Confirm Configuration Changes")}
        primaryCTALabel={t("Confirm Configuration Changes")}
        onPrimaryCTAClick={() => {
          setShowUserJourneyAndDelegationConfirmModal(false);
          handleConfirmSave();
        }}
        onSecondaryCTAClick={() => {
          setShowUserJourneyAndDelegationConfirmModal(false);
        }}
        onClose={() => {
          setShowUserJourneyAndDelegationConfirmModal(false);
        }}
      >
        <ModalBody>
          <Text mb="sp8" fontFamily="paragraphs">
            {t(
              "By enabling share student timeline, parents will be allowed to view all the timeline of usage data for the connected student. To understand the student privacy implications, read the"
            )}{" "}
            <Link href="https://help.linewize.com/hc/en-gb/articles/8634675014044-School-Community-Data-Sharing-Guide" isExternal={true}>
              {t("Data Sharing Guide")}
            </Link>
            .
          </Text>
          <Text mb="sp8" fontFamily="paragraphs">
            {t(
              "By disabling Parental Controls, parents will no longer be able to apply their own filtering policies to their students outside of school hours. Moreover, if you do choose to re-enable it in the future, parents will need to re-configure their filtering policies."
            )}
          </Text>
          <Text mb="sp8" fontFamily="paragraphs">
            {t("Are you sure you want to enable Share Student Timeline and disable Parental Controls?")}
          </Text>
        </ModalBody>
      </Modal>
      <SaveConflictErrorModal
        open={showConflictModal}
        onClose={handleCloseConflictModal}
        onSave={handleOverrideSaveConflict}
        onReload={handleReloadPage}
      />
      <SafetyNetModal
        open={showSafetyNetModal}
        enablingDelegation={
          !storedCommunityConfig?.policyDelegation?.allowPolicyDelegation && !!config?.policyDelegation?.allowPolicyDelegation
        }
        onSave={handleCloseSafetyNetModal}
        onClose={handleCloseSafetyNetModal}
      />
    </>
  );
};

export default CommunityConfiguration;
