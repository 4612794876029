import React, { ReactElement, useEffect, useState } from "react";
import {
  Box,
  Button,
  Icon,
  Link,
  Modal,
  ModalBody,
  InlineNotification,
  SearchBox,
  Text,
  useTranslation,
  useToast,
} from "@familyzone/component-library";
import Api from "../../utils/Api";
import { Guardian, GuardianPlaceholder, Student } from "../../types/Community";
import { guardianSearchURL, useGuardianStore } from "../../storez/GuardianStore";
import { ResponseError } from "../../types/Api";

interface SearchParentModalProps {
  student: Student;
  show: boolean;
  handleHide: () => void;
  handleSuccess: (guardian: Guardian) => void;
  handleAddParent: () => void;
  guardianIdsToExclude: string[];
}

interface Notification {
  status: "error" | "warning";
  title: string;
  message: string | ReactElement;
}

const SearchParentModal: React.FC<SearchParentModalProps> = ({
  student,
  show,
  handleHide,
  handleSuccess,
  handleAddParent,
  guardianIdsToExclude,
}: SearchParentModalProps) => {
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();

  const [loading, setLoading] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [debouncedSearchQuery, setDebouncedSearchQuery] = useState(searchQuery);

  const [parents, setParents] = useState<Guardian[]>([]);
  const [selectedParent, setSelectedParent] = useState<Guardian>(GuardianPlaceholder);
  const [parentSelected, setParentSelected] = useState(false);
  const [notification, setNotification] = useState<Notification | null>(null);

  const [disableAddParentButton, setDisableAddParentButton] = useState(false);

  // Update 'searchQuery' after 0.5 second from the last update of 'debouncedSearchQuery'
  useEffect(() => {
    const timer = setTimeout(() => setSearchQuery(debouncedSearchQuery), 500);
    return () => clearTimeout(timer);
  }, [debouncedSearchQuery]);

  // Submit a new search
  useEffect(() => {
    if (searchQuery !== "") {
      Api.get(
        `${guardianSearchURL()}?q=${searchQuery}`,
        (result: { guardians: Guardian[] }) => {
          let searchResults: Guardian[] = [];
          if (result.guardians.length > 0) {
            const filteredList = result.guardians.filter((guardian) => {
              return !guardianIdsToExclude.includes(guardian.id);
            });
            searchResults = filteredList.slice(0, 5);
          }

          setParents(searchResults);

          if (searchResults.length === 0) {
            setNotification({
              status: "warning",
              title: t("No search results were found"),
              message: t("Please try a different query or add a new parent."),
            });
          }
        },
        () => {
          setNotification({
            status: "error",
            title: t("Something went wrong"),
            message: t("Sorry, we were unable to get search results. Please try again."),
          });
        }
      );
    } else {
      setParents([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery]);

  const handleSelectedParentChange = (parent: Guardian) => {
    if (parent.sourceType !== "LOCAL" && parent.sourceType !== "CSV") {
      setDisableAddParentButton(true);
      setNotification({
        status: "error",
        title: t("The parent cannot be added"),
        message: (
          <Box>
            <Text>
              {t(
                "This parent was imported from an external system and cannot be manually added to a student. Select another record, or request your admin for changes."
              )}
            </Text>
            <Link href="https://docs.linewize.net/help/modifying-parent-data-for-community" isExternal>
              Learn More
            </Link>
          </Box>
        ),
      });
    } else {
      setDisableAddParentButton(false);
    }
    setParentSelected(true);
    setSelectedParent(parent);
  };

  const handleLinkParent = () => {
    setLoading(true);
    void useGuardianStore
      .getState()
      .addUserGuardian(student.username, selectedParent)
      .then(
        (added) => {
          handleSuccess(added);
          handleCloseModal();
          successToast({
            title: t("Parent added successfully"),
            description:
              t("Parent") +
              ` ${added.firstName} ${added.lastName} ` +
              t("has been successfully linked to student") +
              ` ${student.firstName} ${student.lastName}`,
            isClosable: true,
          });
        },
        (err: ResponseError) => {
          errorToast({
            title: t("Please try again"),
            description: t(err.message),
            isClosable: true,
          });
          setLoading(false);
        }
      );
  };

  const handleCloseModal = () => {
    setLoading(false);
    setSearchQuery("");
    setDebouncedSearchQuery("");
    setNotification(null);
    setParentSelected(false);
    setSelectedParent(GuardianPlaceholder);
    handleHide();
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNotification(null);
    setParentSelected(false);
    setSelectedParent(GuardianPlaceholder);
    setDebouncedSearchQuery(event.target.value);
  };

  const handleNewParent = () => {
    handleCloseModal();
    handleAddParent();
  };

  return (
    <Modal
      headerText={t("Add Parent")}
      isOpen={show}
      onClose={handleCloseModal}
      size="sm"
      primaryCTALabel={t("Add Parent")}
      primaryCTADisabled={loading || disableAddParentButton || !parentSelected}
      onPrimaryCTAClick={handleLinkParent}
      secondaryCTALabel={t("Cancel")}
      secondaryCTADisabled={loading}
      onSecondaryCTAClick={handleCloseModal}
    >
      <ModalBody>
        <Text mb="sp4" fontWeight="bold" color="text.title" role="heading">
          {t("Search Parent")}
        </Text>
        <SearchBox data-testid="search-parent-input" placeholder="" onChange={handleChange} onClear={() => setSearchQuery("")} />
        <Box>
          {!parentSelected && parents.length >= 1 && (
            <Box className="search-results">
              {parents.map((parent: Guardian) => (
                <Box
                  data-testid="search-parent-modal-search-result"
                  className="search-result"
                  key={parent.id}
                  onClick={() => handleSelectedParentChange(parent)}
                >
                  <Box>
                    {parent.firstName} {parent.lastName}
                  </Box>
                  <Box>{parent.email}</Box>
                </Box>
              ))}
            </Box>
          )}

          {notification && (
            <Box mt="sp16">
              <InlineNotification
                status={notification.status}
                notificationTitle={notification.title}
                notificationDescription={notification.message}
              />
            </Box>
          )}

          {!parentSelected && (
            <Box my="sp16" textAlign="center">
              {!notification && (
                <Text mb="sp16" color="neutrals.200">
                  {t("Search for an existing parent to link or add a new parent.")}
                </Text>
              )}
              <Button variant="secondary" leftIcon={<Icon icon="fa-plus" variant="solid" color="brand.400" />} onClick={handleNewParent}>
                {t("Add a New Parent")}
              </Button>
            </Box>
          )}

          {parentSelected && (
            <Box my="sp16" data-testid="search-parent-selected-parent">
              <Text mb="sp4" fontWeight="bold" color="text.title" role="heading">
                {t("Parent Details")}
              </Text>
              <Text>{t(`Email: ${selectedParent.email}`)}</Text>
              <Text>{t(`First Name: ${selectedParent.firstName}`)}</Text>
              <Text>{t(`Last Name: ${selectedParent.lastName}`)}</Text>
              <Text>{t(`Source Type: ${selectedParent.sourceType}`)}</Text>
            </Box>
          )}
        </Box>
      </ModalBody>
    </Modal>
  );
};

export default SearchParentModal;
