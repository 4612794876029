import React from "react";
import { sanitize } from "dompurify";
import LeftPanel from "../../utils/LeftPanel";
import LetterPanel from "../../modules/LetterPanel";
import LetterPanelHeader from "../../modules/LetterPanelHeader";
import LetterPanelBody from "../../modules/LetterPanelBody";
import LetterPanelFooter from "../../modules/LetterPanelFooter";
import LetterPanelSaveButton from "../../modules/LetterPanelSaveButton";
import Api from "../../utils/Api";
import { parseFileNameToLogoFile } from "../../utils/FileUtil";
import DumbBusyIndicator from "../../modules/DumbBusyIndicator";
import validateCriteria from "../../modules/criteria/validateCriteria";
import SessionStore from "../../stores/SessionStore";
import { find as _find } from "lodash";
import { Box, Image } from "@familyzone/component-library";

import "react-quill/dist/quill.snow.css";
import ReactQuill from "react-quill";

import "../../../css/BlockPage.css";
import { Input } from "@chakra-ui/input";
import { FilteringSourceCriteria } from "../../modules/criteria/criteriaTypes";
import CriteriaSelector, { CriteriaTypes } from "../../modules/criteria/CriteriaSelector";

/** Add image type to Image element Refer: https://stackoverflow.com/a/70782248 */
declare module "react" {
  interface ImgHTMLAttributes<T> extends HTMLAttributes<T> {
    type?: string;
  }
}

/** Logo file typing */
interface ILogo {
  file: File | null;
  name: string;
}

/** Configuration typing */
interface IConfiguration {
  logo: ILogo;
  is_active: boolean;
  message: string;
  internet_usage_policy: string;
  permission_to_logout: { source_criteria: FilteringSourceCriteria[] };
  device_id: string;
  gcp_bucket_logo_location: string;
  hide_bypass_code: boolean;
}

/** Error message typing */
interface IErrorMessage {
  fileUpload?: string;
  source_criteria?: string;
}

/** Props typing */
export interface IProps {
  configuration: IConfiguration;
  logoDisplayed?: boolean;
  logoReference?: HTMLImageElement;
  changes?: boolean;
  saving?: boolean;
  errorMessages?: IErrorMessage;
  loaded?: boolean;
}

// Note: A query param with random digit is appended to invalidate caching, which was originally for an hour long.
const GCP_BUCKET_LOGO_URL = `https://storage.googleapis.com/PLACEHOLDER?v=${Math.random()}`;

export default class BlockPage extends React.Component {
  constructor(props: IProps) {
    super(props);
    (this.state as Readonly<IProps>) = {
      configuration: {
        logo: {
          file: null,
          name: "",
        },
        is_active: false,
        hide_bypass_code: false,
        message: "",
        internet_usage_policy: "",
        permission_to_logout: { source_criteria: [] },
        // Since we are calling a js method no  to bypass calling any type, this should be a warning
        /* eslint-disable-next-line @typescript-eslint/no-unsafe-call, @typescript-eslint/no-unsafe-member-access */
        device_id: SessionStore.getSelectedDevice() as string,
        gcp_bucket_logo_location: "",
      },
      logoDisplayed: false,
      logoReference: undefined,
      changes: false,
      saving: false,
      errorMessages: {},
      loaded: false,
    };
    if (props.configuration) {
      (this.state as Readonly<IProps>) = {
        configuration: props.configuration,
      };
    }
  }

  componentDidMount(): void {
    this.handle_load();
  }

  handle_load(): void {
    Api.get("/config/ajax/blockedpage", (result: IProps["configuration"]) => {
      if (result) {
        this.setState({
          configuration: { ...(this.state as Readonly<IProps>).configuration, ...result },
          loaded: true,
        });
      }
    });
  }

  onChange(): void {
    const newConfig = (this.state as Readonly<IProps>).configuration;
    newConfig.is_active = !(this.state as Readonly<IProps>).configuration.is_active;
    this.setState({ configuration: newConfig, changes: true });
  }

  onHideBypassCodeChange(): void {
    const newConfig = (this.state as Readonly<IProps>).configuration;
    newConfig.hide_bypass_code = !(this.state as Readonly<IProps>).configuration.hide_bypass_code;
    this.setState({ configuration: newConfig, changes: true });
  }

  handle_RemoveFile(): void {
    const reference: HTMLImageElement = (this.state as Readonly<IProps>).logoReference as HTMLImageElement;
    reference.src = "";
    this.setState({ ...this.state, logoDisplayed: false, logoReference: reference });
    this.mark_dirty();
  }

  handle_UploadFile(event: React.ChangeEvent<HTMLInputElement>): void {
    this.setState({
      errorMessages: {
        fileUpload: "",
      },
    });

    const file: File = ((event.target as HTMLInputElement).files as FileList)[0];
    const filename = parseFileNameToLogoFile(file.name);

    if (file.size / 1000 < 150) {
      const newConfig = (this.state as Readonly<IProps>).configuration;
      newConfig.logo.file = file;
      newConfig.logo.name = filename;
      this.setState({ configuration: newConfig });
      this.mark_dirty();
    } else {
      this.setState({
        errorMessages: {
          fileUpload: "Max file size is 150kb",
        },
      });
    }
  }

  handleQuillChange(value: string): void {
    const newConfig = (this.state as Readonly<IProps>).configuration;
    newConfig.message = sanitize(value.toString());
    this.setState({ configuration: newConfig });
    this.mark_dirty();
  }

  handle_SourceCriteriaChanged = (criteria: FilteringSourceCriteria[]): void => {
    this.setState({
      configuration: {
        ...(this.state as Readonly<IProps>).configuration,
        permission_to_logout: {
          source_criteria: criteria,
        },
      },
      changes: true,
    });
  };

  handle_Submit = (event: Event): void => {
    event.preventDefault();

    this.setState({
      errorMessages: {
        source_criteria: "",
      },
    });

    const [hasValidationErrors, source_criteria_with_validation] = validateCriteria(
      (this.state as Readonly<IProps>).configuration.permission_to_logout.source_criteria
    ) as Array<boolean | []>;

    if (!hasValidationErrors) {
      this.setState({
        saving: true,
      });

      const formData = new FormData();
      formData.append(
        "data",
        JSON.stringify({
          ...(this.state as Readonly<IProps>).configuration,
          logo: { name: (this.state as Readonly<IProps>).configuration.logo.name },
        })
      );
      formData.append("logo", (this.state as Readonly<IProps>).configuration.logo.file as File);

      Api.post_file(
        "/config/ajax/blockedpage",
        formData,
        (result: { upload: boolean }) => {
          if (result.upload && (this.state as Readonly<IProps>).logoReference) {
            const image: HTMLImageElement = (this.state as Readonly<IProps>).logoReference as HTMLImageElement;
            URL.revokeObjectURL(image.src);
            image.src = window.URL.createObjectURL((this.state as Readonly<IProps>).configuration.logo.file as File);
            this.setState({ logoReference: image });
          }

          this.setState({
            saving: false,
            changes: false,
          });
        },
        () => {
          this.setState({
            saving: false,
            changes: false,
          });
        }
      );
    } else {
      const source_criteria_with_error = _find(source_criteria_with_validation as [], "errorMessage") as unknown as {
        errorMessage: string;
      };

      this.setState({
        errorMessages: {
          source_criteria: source_criteria_with_error.errorMessage,
        } as IErrorMessage,
      });
    }
  };

  handle_UpdateInternetUsagePolicy(event: React.ChangeEvent<HTMLInputElement>): void {
    const newConfig = (this.state as Readonly<IProps>).configuration;
    newConfig.internet_usage_policy = (event.target as HTMLInputElement).value;
    this.setState({ configuration: newConfig });
    this.mark_dirty();
  }

  criteriaOptions: Partial<CriteriaTypes> = {
    "exclude.group": "Exclude Group",
    group: "Group",
  };

  render_blockedPageContent(): JSX.Element {
    return (
      <div>
        <div className={"blockpage-active-block"}>
          <div className={"label"}>Hide Bypass Code</div>
          <input
            type="checkbox"
            checked={(this.state as Readonly<IProps>).configuration.hide_bypass_code}
            onChange={this.onHideBypassCodeChange.bind(this)}
          />
        </div>
        <div className="blocked-image-container mui-textfield">
          <div>Custom Logo</div>
          {(this.state as Readonly<IProps>).configuration ? (
            <div>
              {(this.state as Readonly<IProps>).logoDisplayed && (
                <i className="fa fa-times deleteImage" onClick={this.handle_RemoveFile.bind(this)} />
              )}
              <Image
                className="uploaded_image_template"
                src={`${GCP_BUCKET_LOGO_URL.replace(
                  "PLACEHOLDER",
                  `${(this.state as Readonly<IProps>).configuration.gcp_bucket_logo_location}/${
                    (this.state as Readonly<IProps>).configuration.device_id
                  }/logo`
                )}`}
                type="image"
                alt="Block page logo"
                onLoadStart={({ currentTarget }) => {
                  currentTarget.hidden = true;
                }}
                onLoad={({ currentTarget }) => {
                  currentTarget.hidden = false;
                  this.setState({
                    logoDisplayed: true,
                    logoReference: currentTarget,
                  });
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.hidden = true;
                  this.setState({
                    logoDisplayed: false,
                    logoReference: currentTarget,
                  });
                }}
              />

              <img
                className="uploaded_image_template"
                src={`${GCP_BUCKET_LOGO_URL.replace(
                  "PLACEHOLDER",
                  `${(this.state as Readonly<IProps>).configuration.gcp_bucket_logo_location}/${
                    (this.state as Readonly<IProps>).configuration.device_id
                  }/logo.svg`
                )}`}
                type="image/svg+xml"
                alt="Svg block page logo"
                onLoadStart={({ currentTarget }) => {
                  currentTarget.hidden = true;
                }}
                onLoad={({ currentTarget }) => {
                  currentTarget.hidden = false;
                  this.setState({
                    logoReference: currentTarget,
                    logoDisplayed: true,
                  });
                }}
                onError={({ currentTarget }) => {
                  currentTarget.onerror = null;
                  currentTarget.hidden = true;
                }}
              />
            </div>
          ) : (
            <div />
          )}
          <input
            data-testid="blockedLogo"
            type="file"
            name="blockedLogo"
            className="blocked-logo mui-input"
            onChange={this.handle_UploadFile.bind(this)}
            accept="image/*"
          />
          <div className={"errorMessage"}>{((this.state as Readonly<IProps>).errorMessages as IErrorMessage).fileUpload}</div>
        </div>
        <div className="blocked-message-container mui-textfield">
          <div>Custom Block Message</div>
          <ReactQuill
            defaultValue={sanitize((this.state as Readonly<IProps>).configuration.message)}
            onChange={this.handleQuillChange.bind(this)}
          />
        </div>
        <div className={"blocked-internetPolicy-container mui-textfield"}>
          <div>Internet Usage Policy Link</div>
          <Input
            type="text"
            value={(this.state as Readonly<IProps>).configuration.internet_usage_policy}
            name="blockedInternetUsagePolicy"
            className="blocked-internetPolicy"
            onChange={this.handle_UpdateInternetUsagePolicy.bind(this)}
          />
        </div>
        <div className="blocked-logout-container mui-textfield">
          <div className="errorMessage">{((this.state as Readonly<IProps>).errorMessages as IErrorMessage).source_criteria}</div>
          <div className="formgroup-element">
            <div className="formgroup-element-title">Permission to Logout from the block page</div>
            <Box marginTop="sp16">
              <CriteriaSelector
                selected={(this.state as Readonly<IProps>).configuration.permission_to_logout.source_criteria}
                errors={[]}
                onChange={this.handle_SourceCriteriaChanged}
                customOptions={this.criteriaOptions}
              />
            </Box>
          </div>
        </div>
      </div>
    );
  }

  mark_dirty(): void {
    this.setState({ changes: true });
  }

  render(): JSX.Element {
    return (
      <LeftPanel>
        <LetterPanel>
          <LetterPanelHeader>
            Block Page
            <DumbBusyIndicator loaded={(this.state as Readonly<IProps>).loaded} />
          </LetterPanelHeader>
          <LetterPanelBody>
            <div className={"formgroup-content blockpage-content"}>
              <div className={"blockpage-active-block"}>
                <div className={"label"}>Enabled</div>
                <input
                  type="checkbox"
                  checked={(this.state as Readonly<IProps>).configuration.is_active}
                  onChange={this.onChange.bind(this)}
                />
              </div>
              {(this.state as Readonly<IProps>).configuration.is_active ? this.render_blockedPageContent() : <div />}
            </div>
          </LetterPanelBody>
          <LetterPanelFooter>
            <LetterPanelSaveButton
              onClick={this.handle_Submit.bind(this)}
              changes={(this.state as Readonly<IProps>).changes}
              saving={(this.state as Readonly<IProps>).saving}
            />
          </LetterPanelFooter>
        </LetterPanel>
      </LeftPanel>
    );
  }
}
