import React, { useCallback, useEffect, useState } from "react";
import { Tooltip } from "@chakra-ui/react";
import { Box, Button, Flex, Icon, IconButton, Link, Spinner, Text, useToast, useTranslation } from "@familyzone/component-library";
import { useCommunityFeatureFlagStore } from "../../../storez/CommunityFeatureFlagStore";
import { useCommunityConfigStore } from "../../../storez/CommunityConfigStore";
import { ResponseError } from "../../../types/Api";
import { useGuardianStore } from "../../../storez/GuardianStore";
import {
  DisconnectEvent,
  DisconnectEventPlaceholder,
  DisconnectType,
  Guardian,
  GuardianPlaceholder,
  isLocalGuardian,
  replaceInArray,
  Student,
} from "../../../types/Community";
import AddParentModal from "../../modals/AddParentModal";
import EditParentModal from "../../modals/EditParentModal";
import StudentDisconnectModal from "../../modals/StudentDisconnectModal";
import SearchParentModal from "../../modals/SearchParentModal";

const GuardianCard: React.FC<{
  student: Student;
  guardian: Guardian;
  disabled?: boolean;
  onEdit: () => void;
  onDisconnect: () => void;
  onUnlink: () => void;
}> = ({ student, guardian, disabled, onEdit, onDisconnect, onUnlink }) => {
  const { t } = useTranslation();

  const [communityConfig] = useCommunityConfigStore(useCallback((state) => [state.config] as const, []));

  const canEdit = !disabled && !student.archived && isLocalGuardian(guardian) && !guardian.claimed;
  const canUnlink = !disabled && isLocalGuardian(guardian) && !guardian.claimed;
  const canDisconnect = !disabled && guardian.claimed;

  const handleDisconnect = (e: React.MouseEvent) => {
    e.preventDefault();
    onDisconnect();
  };

  const handleUnlink = (e: React.MouseEvent) => {
    e.preventDefault();
    onUnlink();
  };

  return (
    <Flex
      direction="column"
      width="380px"
      p="sp24"
      backgroundColor="white"
      border="1px"
      borderColor="neutrals.40"
      borderRadius="md"
      boxShadow={20}
      _hover={{ borderColor: "text.link.hover", boxShadow: 50 }}
      data-testid="parent-details-card"
    >
      <Flex justifyContent="space-between">
        <Box>
          <Text as="h2" fontFamily="Poppins" fontSize="lg" fontWeight="medium" color="text.title">
            {guardian.firstName} {guardian.middleName} {guardian.lastName}
          </Text>
          <Text fontSize="sm" fontWeight="normal" color="text.paragraph.regular" wordBreak="break-word">
            {guardian.email}
          </Text>
        </Box>
        <Box ml="sp16">
          <Tooltip
            variant="dark"
            placement="top-start"
            hasArrow={true}
            maxWidth="304px"
            isDisabled={canEdit}
            label={
              !isLocalGuardian(guardian) ? "Synced parent cannot be edited" : guardian.claimed ? "Connected parent cannot be edited" : ""
            }
          >
            <Box>
              <IconButton
                variant="secondary"
                size="sm"
                icon={<Icon icon="fa-edit" />}
                aria-label={t("Edit Parent Details")}
                disabled={!canEdit}
                onClick={onEdit}
              />
            </Box>
          </Tooltip>
        </Box>
      </Flex>

      <Flex mt="sp16" justifyContent="space-between" color="text.title">
        <Text>{t("Source Type")}</Text>
        <Text textTransform="capitalize">{guardian.sourceType.toLowerCase()}</Text>
      </Flex>

      {communityConfig?.policyDelegation?.allowPolicyDelegation && (
        <Flex mt="sp16" justifyContent="space-between" color="text.title">
          <Text>{t("Parental Controls")}</Text>
          <Text>{guardian.delegated ? t("Accepted") : t("Not Accepted")}</Text>
        </Flex>
      )}

      <Flex mt="sp16" justifyContent="end">
        {canDisconnect && (
          <Link color="error.title" fontWeight="bold" href="#" onClick={handleDisconnect}>
            {t("Disconnect Parent")}
          </Link>
        )}
        {canUnlink && (
          <Link color="error.title" fontWeight="bold" href="#" onClick={handleUnlink}>
            {t("Remove Parent")}
          </Link>
        )}
      </Flex>
    </Flex>
  );
};

interface ManageUserGuardianProps {
  student: Student;
  onGuardiansChange: (guardians: Guardian[]) => void;
}

export const ManageUserGuardians: React.FC<ManageUserGuardianProps> = ({ student, onGuardiansChange }) => {
  const { t } = useTranslation();
  const { errorToast, successToast } = useToast();

  const [loading, setLoading] = useState(true);
  const [showSearchGuardianModal, setShowSearchGuardianModal] = useState<boolean>(false);
  const [showAddGuardianModal, setShowAddGuardianModal] = useState<boolean>(false);
  const [showEditGuardianModal, setShowEditGuardianModal] = useState<boolean>(false);
  const [guardians, setGuardians] = useState<Guardian[]>([]);
  const [guardianToEdit, setGuardianToEdit] = useState<Guardian>();
  const [disconnectEvent, setDisconnectEvent] = useState<DisconnectEvent>();

  const [featureFlags, getOrFetchFeatureFlags] = useCommunityFeatureFlagStore(
    useCallback((store) => [store.flags, store.getOrFetch] as const, [])
  );
  const [communityConfig, fetchCommunityConfig] = useCommunityConfigStore(useCallback((state) => [state.config, state.fetch] as const, []));

  const [fetchGuardians, removeGuardian, disconnectGuardian] = useGuardianStore(
    useCallback((store) => [store.fetchUserGuardians, store.removeUserGuardian, store.disconnectGuardian] as const, [])
  );

  useEffect(() => {
    if (!featureFlags) {
      // wait for flags to load before trying to fetch any other data
      return;
    }

    if (!featureFlags.enableCommunity) {
      setLoading(false);
      return;
    }

    const promises = [];

    promises.push(fetchGuardians(student.username).then(setGuardians));

    if (!communityConfig) {
      // avoid re-fetching the config when switching between different users
      promises.push(fetchCommunityConfig());
    }

    void Promise.all(promises).then(
      () => {
        setLoading(false);
      },
      (err: ResponseError) => {
        errorToast({
          title: t("Please try again"),
          description: t(err.message),
          isClosable: true,
        });
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [featureFlags, fetchCommunityConfig, fetchGuardians]);

  useEffect(() => {
    void getOrFetchFeatureFlags();
  }, [getOrFetchFeatureFlags]);

  useEffect(() => {
    onGuardiansChange(guardians);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [guardians]);

  const showErrorToast = (message: string) => {
    errorToast({
      title: t("Please try again"),
      description: t(message),
      isClosable: true,
    });
  };

  const handleAddGuardianSuccess = (added: Guardian) => {
    setGuardians((existing) => {
      if (existing.every((g) => g.email !== added.email)) {
        return existing.concat(added);
      }
      return existing;
    });
    setShowSearchGuardianModal(false);
    setShowAddGuardianModal(false);
  };

  const handleRemoveGuardian = (guardian: Guardian) => {
    setDisconnectEvent({ student, guardian, type: DisconnectType.UnlinkParentFromStudent });
  };

  const handleEdit = (guardian: Guardian): void => {
    setGuardianToEdit(guardian);
    setShowEditGuardianModal(true);
  };

  const handleCancelEdit = () => {
    setShowEditGuardianModal(false);
    setGuardianToEdit(undefined);
  };

  const handleUpdateGuardianSuccess = (updated: Guardian): void => {
    if (guardianToEdit) {
      setGuardians((guardians) => replaceInArray(guardians, guardianToEdit, updated));
      setShowEditGuardianModal(false);
      setGuardianToEdit(undefined);
    }
  };

  const handleDisconnect = (guardian: Guardian): void => {
    setDisconnectEvent({ student, guardian, type: DisconnectType.DisconnectParentFromStudent });
  };

  const handleCancelDisconnect = (): void => {
    setDisconnectEvent(undefined);
  };

  const handleConfirmDisconnect = (): void => {
    if (disconnectEvent?.type === DisconnectType.DisconnectParentFromStudent) {
      void disconnectGuardian(student.username, disconnectEvent.guardian).then(
        (disconnected) => {
          setGuardians((guardians) => replaceInArray(guardians, disconnectEvent.guardian, disconnected));
          successToast({
            title: t("Parent disconnected successfully"),
            description:
              t("Parent") +
              ` ${disconnected.firstName} ${disconnected.lastName} ` +
              t("has been successfully disconnected from student") +
              ` ${student.firstName} ${student.lastName}`,
            isClosable: true,
          });
          setDisconnectEvent(undefined);
        },
        (err: ResponseError) => showErrorToast(err.message)
      );
    } else if (disconnectEvent?.type === DisconnectType.UnlinkParentFromStudent) {
      void removeGuardian(student.username, disconnectEvent?.guardian).then(
        (removed) => {
          setGuardians((guardians) => guardians.filter((g) => g.id !== removed.id));
          successToast({
            title: t("Parent removed successfully"),
            description:
              t("Parent") +
              ` ${removed.firstName} ${removed.lastName} ` +
              t("has been successfully removed from student") +
              ` ${student.firstName} ${student.lastName}`,
            isClosable: true,
          });
          setDisconnectEvent(undefined);
        },
        (err: ResponseError) => showErrorToast(err.message)
      );
    }
  };

  if (!featureFlags?.enableCommunity) {
    return <></>;
  }

  return (
    <Box maxWidth={guardians.length === 0 ? "65%" : "1640px"}>
      <Text fontFamily="heading" fontSize="xl" color="text.title" mb="sp4" pb="md" role="heading">
        {t("Parent Details")}
      </Text>

      <Flex flexWrap="wrap" minHeight="100px" gap="sp24" p="sp24" mb="sp16" borderRadius="md" backgroundColor="neutrals.10">
        {loading && guardians.length === 0 && (
          <Flex flexGrow={1} alignItems="center" justifyContent="center" data-testid="spinner">
            <Spinner size="md" />
          </Flex>
        )}
        {!loading && guardians.length === 0 && (
          <Flex flexGrow={1} alignItems="center" justifyContent="center">
            <Text color="neutrals.200">The student does not have any parents</Text>
          </Flex>
        )}
        {guardians.map((g) => (
          <GuardianCard
            key={g.id}
            student={student}
            guardian={g}
            disabled={loading}
            onEdit={() => handleEdit(g)}
            onDisconnect={() => handleDisconnect(g)}
            onUnlink={() => handleRemoveGuardian(g)}
          />
        ))}
      </Flex>

      {!student.archived && (
        <>
          <Flex>
            <Tooltip
              variant="dark"
              placement="top-start"
              hasArrow={true}
              maxWidth="304px"
              isDisabled={!!student.firstName && !!student.lastName}
              label={t("User must have first and last name to add parents")}
            >
              <Box>
                <Button
                  variant="secondary"
                  onClick={() => setShowSearchGuardianModal(true)}
                  disabled={loading || !student.firstName || !student.lastName}
                >
                  {t("Add Parent")}
                </Button>
              </Box>
            </Tooltip>
          </Flex>
          <SearchParentModal
            show={showSearchGuardianModal}
            student={student}
            handleHide={() => setShowSearchGuardianModal(false)}
            handleSuccess={handleAddGuardianSuccess}
            handleAddParent={() => {
              setShowSearchGuardianModal(false);
              setShowAddGuardianModal(true);
            }}
            guardianIdsToExclude={guardians.map((g) => g.id)}
          />
          <AddParentModal
            show={showAddGuardianModal}
            student={student}
            guardians={guardians}
            handleSuccess={handleAddGuardianSuccess}
            handleHide={() => setShowAddGuardianModal(false)}
          />
          <EditParentModal
            show={showEditGuardianModal}
            guardian={guardianToEdit ?? GuardianPlaceholder}
            handleHide={handleCancelEdit}
            handleSuccess={handleUpdateGuardianSuccess}
          />
          <StudentDisconnectModal
            open={!!disconnectEvent}
            event={disconnectEvent ?? DisconnectEventPlaceholder}
            onClose={handleCancelDisconnect}
            onConfirm={handleConfirmDisconnect}
          />
        </>
      )}
    </Box>
  );
};
