import React from "react";

import FlexibleTable from "../../modules/FlexibleTable";
import ChildrenCentricPage from "../../modules/ChildrenCentricPage";
import LineGraph from "../../modules/LineGraph";
import Api from "../../utils/Api";
import TableLeftPanel from "../../utils/TableLeftPanel";
import moment from "moment";
import SearchableSelect from "../../modules/SearchableSelect";
import { useDateRangeFilterStore } from "../../storez/DateRangeFilterStore";
import GlobalDatePickerVisibilityActions from "../../actions/GlobalDatePickerVisibilityActions";
import PropTypes from "prop-types";
import { QuestionIcon } from "@chakra-ui/icons";
import { Tooltip } from "@chakra-ui/react";
import PortalHoc from "../../modules/PortalHoc";
import { Link } from "@familyzone/component-library";

const SPHIREOS_PERFORMANCE_METRICS = [
  "appliance_gateway.connections",
  "auth.radius.accounting_packets.persec",
  "configuration.version",
  "firewall.arp.size",
  "firewall.conntracker.container.size",
  "firewall.conntracker.gbremovedconnections",
  "firewall.conntracker.icmp.size",
  "firewall.conntracker.tcp.size",
  "firewall.conntracker.total.connections",
  "firewall.conntracker.udp.size",
  "firewall.fzagent.bypass.bytes.persec",
  "firewall.fzagent.bypass.devices.size",
  "firewall.fzagent.bypass.packets.persec",
  "firewall.fzagent.bypass.persec",
  "firewall.transfer.bytes.persec",
  "firewall.transfer.packets.persec",
  "firewall.transfer.persec",
  "nfqueue.filter.average_handling_time.us",
  "sessiondb.size",
  "sphirewall.version",
  "sysctl.net.netfilter.nf_conntrack_count",
  "system.cpu_usage",
  "system.fs.configuration.available",
  "system.fs.configuration.total",
  "system.fs.log.available",
  "system.fs.log.total",
  "system.fs.root.available",
  "system.fs.root.total",
  "system.proc.meminfo.MemAvailable",
  "system.proc.meminfo.MemTotal",
  "system.utime",
];

export default class DiagnosticsMetrics extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      dynamic_metric: context.router.location.query.startingMetric || "system.utime",
      dynamic_metric_data: [],
      loaded: false,
    };

    this.unsubFilterStore = null;
    this.handle_MetricChange = this.handle_MetricChange.bind(this);
  }

  componentDidMount = () => {
    this.unsubFilterStore = useDateRangeFilterStore.subscribe(this.onChange);
    setTimeout(GlobalDatePickerVisibilityActions.showGlobalDatePicker, 0);

    this.onChange();
  };

  componentWillUnmount() {
    this.unsubFilterStore();
  }

  onChange = () => {
    this.handle_load_metric();
  };

  handle_load_metric = () => {
    let self = this;

    Api.get_analytics(
      "/managedevice/ajax/device/metric/" + this.state.dynamic_metric,
      (result) => {
        self.setState({
          dynamic_metric_data: result["data"],
          loaded: true,
        });
      },
      (err) => {
        console.log(err);
      }
    );
  };

  handle_MetricChange = (event) => {
    this.setState(
      {
        dynamic_metric: event.value,
        loaded: false,
      },
      () => {
        this.handle_load_metric();
      }
    );
  };

  render__buttons = () => {
    return <div />;
  };

  prepare_columns = () => {
    return [
      {
        title: "Time",
        data: function (row) {
          let day = moment.unix(row["time"]);
          return day.format("YYYY-MM-DD HH:mm:ss");
        },
        export: function (row) {
          let day = moment.unix(row["time"]);
          return day.format("YYYY-MM-DD HH:mm:ss");
        },
        search: FlexibleTable.search__single_string_field("time"),
        onclick: undefined,
      },
      {
        title: "Value",
        data: function (row) {
          return row["value"];
        },
        export: function (row) {
          return row["value"];
        },
        onclick: undefined,
      },
    ];
  };

  get_SelectionMap = () => {
    let ret = [];
    for (let metric of SPHIREOS_PERFORMANCE_METRICS) {
      ret.push([metric, metric]);
    }

    return ret;
  };

  render__content = () => {
    return (
      <div>
        <div className="main-content-sub-element">
          <div className="row">
            <div className="col-md-12">
              <div>
                <SearchableSelect
                  value={this.state.dynamic_metric}
                  onChange={this.handle_MetricChange}
                  valueDataMap={this.get_SelectionMap()}
                  clearable={false}
                />
              </div>
            </div>
          </div>
        </div>
        <div className="main-content-sub-element line-chart-container">
          <div className="row">
            <div className="col-md-12">
              <LineGraph
                data={this.state.dynamic_metric_data}
                container="dynamic_graph"
                xAxisFormatter="formatUnixTimestamp"
                dataLoaded={this.state.loaded}
              />
            </div>
          </div>
        </div>
        <div className="main-content-sub-element">
          <FlexibleTable
            columns={this.prepare_columns()}
            data={this.state.dynamic_metric_data}
            sort_column={0}
            sort_asc={true}
            loaded={this.state.loaded}
            className={"performance-metrics"}
          />
        </div>
      </div>
    );
  };

  renderTooltip = () => (
    <PortalHoc elementId="fz_table_header">
      <Tooltip variant="dark" hasArrow={true} maxWidth="336px" fontSize="md" label="Click for help">
        <Link
          isExternal
          href={"https://help.linewize.com/hc/en-gb/articles/12980554058012-School-Manager-Performance-Metrics"}
          color="currentColor"
          order={2}
        >
          <QuestionIcon boxSize={6} ml={7} />
        </Link>
      </Tooltip>
    </PortalHoc>
  );

  render() {
    return (
      <TableLeftPanel>
        <ChildrenCentricPage icon="fa fa-cog" title="Performance Metrics" buttons={this.render__buttons()}>
          {this.renderTooltip()}
          {this.render__content()}
        </ChildrenCentricPage>
      </TableLeftPanel>
    );
  }
}

DiagnosticsMetrics.contextTypes = {
  router: PropTypes.object.isRequired,
};
