import { GroupUMS } from "./Groups";

export enum DataSharingScope {
  ALL = "all",
  SCHOOL_TIME = "school_time",
  OFF_SCHOOL = "off_school",
}

export enum DelegationSchoolReportingScope {
  ALL = "all",
  BLOCKED = "blocked",
  NONE = "none",
}

const DelegationSchoolReportingScopeToDisplayName = {
  [DelegationSchoolReportingScope.ALL]: "All usage",
  [DelegationSchoolReportingScope.BLOCKED]: "Blocked activity only",
  [DelegationSchoolReportingScope.NONE]: "No usage",
};

export interface CommunityConfig {
  featureFlags?: CommunityFlags;
  studentUsage?: {
    shareStudentUsage?: boolean;
    shareUserJourney?: boolean;
    shareAllowedActivity?: boolean;
    shareBlockedActivity?: boolean;
    shareSearchTerms?: boolean;
    shareAccessedVideos?: boolean;
    journeySharingScope?: DataSharingScope;
    excludeUsers?: User[];
    excludeGroups?: Group[];
  };
  pauseInternet?: {
    allowPauseInternet?: boolean;
    allowPause247?: boolean;
  };
  policyDelegation?: {
    allowPolicyDelegation?: boolean;
    applySafetyNet?: boolean;
    applySafeSearch?: boolean;
    schoolReportingScope?: DelegationSchoolReportingScope;
    excludeUsers?: User[];
    excludeGroups?: Group[];
    includeUsers?: User[];
    includeGroups?: Group[];
  };
  version?: number;
}

export interface User {
  id: string;
  username: string;
}

export interface Group {
  id: string;
  sourceName: string;
  sourceType?: GroupUMS["sourceType"];
  distinguishedName?: string;
  description?: string;
}

export interface ExclusionConfig {
  excludeGroups?: Group[];
  excludeUsers?: User[];
}

export interface InclusionConfig {
  includeGroups?: Group[];
  includeUsers?: User[];
}

export const UserFromPartial = (u: Partial<User>): User => {
  return {
    id: u.id ?? "",
    username: u.username ?? "",
  };
};

const userEquals = (u1: User, u2: User): boolean => {
  return u1.id === u2.id && u1.username === u2.username;
};

const groupEquals = (g1: Group, g2: Group): boolean => {
  return g1.id === g2.id && g1.sourceName === g2.sourceName;
};

const arrayEquals = <T>(arr1: T[], arr2: T[], comparator: (o1: T, o2: T) => boolean): boolean => {
  return arr1.length === arr2.length && arr1.every((o1) => arr2.some((o2) => comparator(o1, o2)));
};

export const CommunityConfigEquals = (c1?: CommunityConfig, c2?: CommunityConfig): boolean => {
  if (!c1 || !c2) {
    return c1 === c2;
  }

  let equals =
    c1.studentUsage?.shareStudentUsage === c2.studentUsage?.shareStudentUsage &&
    c1.studentUsage?.shareUserJourney === c2.studentUsage?.shareUserJourney &&
    c1.studentUsage?.shareAllowedActivity === c2.studentUsage?.shareAllowedActivity &&
    c1.studentUsage?.shareBlockedActivity === c2.studentUsage?.shareBlockedActivity &&
    c1.studentUsage?.shareSearchTerms === c2.studentUsage?.shareSearchTerms &&
    c1.studentUsage?.shareAccessedVideos === c2.studentUsage?.shareAccessedVideos &&
    c1.studentUsage?.journeySharingScope === c2.studentUsage?.journeySharingScope &&
    c1.pauseInternet?.allowPauseInternet === c2.pauseInternet?.allowPauseInternet &&
    c1.pauseInternet?.allowPause247 === c2.pauseInternet?.allowPause247 &&
    c1.policyDelegation?.allowPolicyDelegation === c2.policyDelegation?.allowPolicyDelegation &&
    c1.policyDelegation?.applySafetyNet === c2.policyDelegation?.applySafetyNet &&
    c1.policyDelegation?.applySafeSearch === c2.policyDelegation?.applySafeSearch &&
    c1.policyDelegation?.schoolReportingScope === c2.policyDelegation?.schoolReportingScope &&
    c1.version === c2.version;

  if (equals) {
    const arr1 = c1.studentUsage?.excludeUsers ?? [];
    const arr2 = c2.studentUsage?.excludeUsers ?? [];
    equals = arrayEquals(arr1, arr2, userEquals);
  }

  if (equals) {
    const arr1 = c1.studentUsage?.excludeGroups ?? [];
    const arr2 = c2.studentUsage?.excludeGroups ?? [];
    equals = arrayEquals(arr1, arr2, groupEquals);
  }

  if (equals) {
    const arr1 = c1.policyDelegation?.excludeUsers ?? [];
    const arr2 = c2.policyDelegation?.excludeUsers ?? [];
    equals = arrayEquals(arr1, arr2, userEquals);
  }

  if (equals) {
    const arr1 = c1.policyDelegation?.excludeGroups ?? [];
    const arr2 = c2.policyDelegation?.excludeGroups ?? [];
    equals = arrayEquals(arr1, arr2, groupEquals);
  }

  if (equals) {
    const arr1 = c1.policyDelegation?.includeUsers ?? [];
    const arr2 = c2.policyDelegation?.includeUsers ?? [];
    equals = arrayEquals(arr1, arr2, userEquals);
  }

  if (equals) {
    const arr1 = c1.policyDelegation?.includeGroups ?? [];
    const arr2 = c2.policyDelegation?.includeGroups ?? [];
    equals = arrayEquals(arr1, arr2, groupEquals);
  }

  return equals;
};

export const MapDelegationSchoolReportingScopeToOption = (
  scope: DelegationSchoolReportingScope
): { value: string; scope: DelegationSchoolReportingScope } => {
  return { value: DelegationSchoolReportingScopeToDisplayName[scope], scope: scope };
};

//---

export interface CommunityFlags {
  enableCommunity?: boolean;
  enableCommunityDashboard?: boolean;
  enableWeeklyCampaign?: boolean;
  enableDelegation?: boolean;
  enableDelegationByInclusion?: boolean;
  version?: number;
}

export const CommunityFlagsEquals = (f1?: CommunityFlags, f2?: CommunityFlags): boolean => {
  if (!f1 || !f2) {
    return f1 === f2;
  }

  return (
    f1.enableCommunity === f2.enableCommunity &&
    f1.enableCommunityDashboard === f2.enableCommunityDashboard &&
    f1.enableWeeklyCampaign === f2.enableWeeklyCampaign &&
    f1.enableDelegation === f2.enableDelegation &&
    f1.enableDelegationByInclusion === f2.enableDelegationByInclusion &&
    f1.version === f2.version
  );
};

export const ValidateExpectedCommunityFlags = (expectedFlags: CommunityFlags, flags: CommunityFlags): boolean => {
  for (const [key, value] of Object.entries(expectedFlags)) {
    if (value !== undefined && value !== flags[key as keyof CommunityFlags]) {
      return false;
    }
  }
  return true;
};

//---

export interface BaseGuardian {
  firstName: string;
  middleName?: string;
  lastName: string;
  email: string;
  sourceType: string;
}

export interface Guardian extends BaseGuardian {
  id: string;
  // present only in Manage User context
  claimed?: boolean;
  // present only in Manage User context
  delegated?: boolean;
  // present only in Parent Management context
  linkCount?: number;
  // present only in Parent Management context
  claimCount?: number;
  // present only in Parent Management context
  delegatedCount?: number;
}

/**
 * @deprecated Use {@link UserGuardian} instead. To be removed by COMMUNITY-1446.
 */
export interface LinkedGuardian extends Guardian {
  canBeUnlinked?: boolean;
  delegated?: boolean;
}

export const isLocalGuardian = (g: BaseGuardian): boolean => {
  return !!g.sourceType && ["CSV", "LOCAL"].includes(g.sourceType.toUpperCase());
};

export interface Student {
  id: string;
  firstName: string;
  middleName?: string;
  lastName: string;
  email?: string;
  username: string;
  archived?: boolean;
  claimed?: boolean;
  delegated?: boolean;
  sourceType: string;
}

export enum DisconnectType {
  DisconnectStudentFromParent,
  UnlinkStudentFromParent,
  DisconnectParentFromStudent,
  UnlinkParentFromStudent,
}

export interface DisconnectEvent {
  guardian: Guardian;
  student: Student;
  type: DisconnectType;
}

export const GuardianPlaceholder: Readonly<Guardian> = Object.freeze({ id: "", firstName: "", lastName: "", email: "", sourceType: "" });
export const StudentPlaceholder: Readonly<Student> = Object.freeze({
  id: "",
  firstName: "",
  lastName: "",
  username: "",
  email: "",
  sourceType: "",
});
export const DisconnectEventPlaceholder: Readonly<DisconnectEvent> = Object.freeze({
  guardian: GuardianPlaceholder,
  student: StudentPlaceholder,
  type: DisconnectType.DisconnectStudentFromParent,
});

export const replaceInArray = <T extends { id: string }>(guardians: T[], existing: T, modified: T): T[] => {
  const updated: T[] = [];
  for (const g of guardians) {
    if (g.id === existing.id) {
      updated.push(modified);
    } else {
      updated.push(g);
    }
  }
  return updated;
};

//--- Community Dashboard

export interface LinkAndClaimStats {
  linkedCount: number;
  claimedCount: number;
}

export interface LatestSync {
  latestSyncTimestamp: number;
}

export interface CommunityDashboardData {
  linkAndClaimStats: LinkAndClaimStats;
  latestSync: LatestSync;
}

export const CommunityDashboardDataPlaceholder: Readonly<CommunityDashboardData> = Object.freeze({
  linkAndClaimStats: { linkedCount: 0, claimedCount: 0 },
  latestSync: { latestSyncTimestamp: 0 },
});

// ---

export enum CsvUploadState {
  Initiated = "INITIATED",
  Running = "RUNNING",
  Complete = "COMPLETE",
  Failed = "FAILED",
}

export interface CsvImportStats {
  additions?: number;
  deletions?: number;
  invalid_rows?: number;
}

export interface CsvUploadStatus {
  files: {
    inputDataUrl: string;
    failureReportUrl?: string;
    successReportUrl?: string;
  };
  startTime: string;
  finishTime?: string;
  // it breaks my heart that the naming is inconsistent in API between statuses :(
  start?: string;
  finish?: string;
  status: CsvUploadState;
  success?: string;
}

const isImportStatsType = (o: unknown): o is CsvImportStats => {
  return (o as CsvImportStats) !== undefined;
};

export const ParseImportStats = (jsonString: string): CsvImportStats | undefined => {
  try {
    // eslint-disable-next-line
    let result = JSON.parse(jsonString);
    if (typeof result === "string") {
      // eslint-disable-next-line
      result = JSON.parse(result);
    }

    if (isImportStatsType(result)) {
      return result;
    }
  } catch (err) {
    console.error(err);
  }
  return undefined;
};
