import userflow from "userflow.js";

declare module "userflow.js" {
  export function init(token: string): void;
  export function identify(
    userId: string,
    attributes: {
      sm_email?: string;
      user_timezone: string;
      sm_user_onb_optOut: boolean;
      sm_user_rfresh_opOut: boolean;
      sm_user_nps: number;
      user_theme: string;
      sm_user_join: string;
      user_language: string;
    }
  ): void;
  export function group(
    groupId: string,
    groupAttributes: {
      sm_appliance_name: string;
      sm_appliance_id: string;
      sm_region: string;
      sm_timezone: string;
    },
    membershipAttributes: {
      membership: {
        application: string;
        sm_role: string[];
        sm_config: string;
      };
    }
  ): void;
  export function reset(): void;
}

const getUserBrowserLanguage = (): string => {
  const nav = typeof navigator !== "undefined" ? navigator : ({} as Navigator);
  if ("language" in nav && typeof nav.language === "string") {
    return nav.language;
  } else {
    return "en-US";
  }
};

export async function initializeUserFlow(
  applianceRegion: string,
  username: string,
  applianceId: string,
  applianceName: string,
  appliancePermissions: string,
  applianceTimezone: string
): Promise<void> {
  const ufTokenSitStg = process.env.REACT_APP_USERFLOW_TOKEN_SIT_STG;
  const ufTokenPRD = process.env.REACT_APP_USERFLOW_TOKEN_PRD;
  const userLanguage = getUserBrowserLanguage();
  const userTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  if (!ufTokenSitStg || !ufTokenPRD) {
    console.error("UserFlow token(s) are undefined");
    return;
  }

  let selectedToken: string;

  if (applianceRegion === "sit" || applianceRegion === "beta-1") {
    selectedToken = ufTokenSitStg;
  } else {
    selectedToken = ufTokenPRD;
  }

  userflow.init(selectedToken);

  if (username) {
    await userflow.identify(username, {
      sm_email: username,
      user_timezone: userTimezone,
      user_language: userLanguage,
      sm_user_onb_optOut: { set_once: false },
      sm_user_rfresh_opOut: { set_once: false },
      sm_user_nps: { set_once: 0 },
      user_theme: { set_once: "N/A" },
      sm_user_join: { set_once: "N/A" },
    });

    const groupId = applianceId;
    if (groupId) {
      const groupAttributes = {
        sm_appliance_name: applianceName,
        sm_appliance_id: applianceId,
        sm_region: applianceRegion,
        sm_timezone: applianceTimezone,
      };
      const membershipAttributes = {
        membership: {
          application: { prepend: "School Manager" },
          sm_role: appliancePermissions,
          sm_config: { prepend: "Access" },
        },
      };

      await userflow.group(groupId, groupAttributes, membershipAttributes);
    } else {
      console.error("Group ID is undefined");
    }
  }
}

export function resetUserFlow(): void {
  userflow.reset();
}
