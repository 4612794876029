import { create } from "zustand";
import { jqXHR, ResponseError } from "../types/Api";
import { CalendarRule, CalendarRuleIsNonSchoolRule, NonSchoolRule, RecurrenceID, RuleType } from "../types/Calendar";
import Api from "../utils/Api";
import { useSessionStore } from "./SessionStore";

export const calendarRulesURL = (): string => {
  const device = useSessionStore.getState().getDevice();
  return `/cadm/v1/appliances/${device.id}/calendar-rules`;
};

export enum RuleUpdateType {
  ONLY_THIS_INSTANCE = "false",
  THIS_AND_FUTURE_INSTANCES = "true",
}

export interface SaveOptions {
  fixVersionConflict?: boolean;
  recurrenceId?: RecurrenceID;
  updateType?: RuleUpdateType;
}

export interface DeleteOptions {
  recurrenceId?: RecurrenceID;
  deleteType?: RuleUpdateType;
}

export interface SchoolCalendarStore {
  schoolTimeRule?: CalendarRule;
  otherRules: NonSchoolRule[];
  timezone?: string;
  fetch: () => Promise<CalendarRule | undefined>;
  fetchNonSchoolDays: (year: number) => Promise<NonSchoolRule[]>;
  save: (rule: CalendarRule, options?: SaveOptions) => Promise<CalendarRule>;
  delete: (rule: NonSchoolRule, options?: DeleteOptions) => Promise<void>;
  reset: () => void;
}

export const useSchoolCalendarStore = create<SchoolCalendarStore>((set, get) => ({
  // type `school_time` rule
  schoolTimeRule: undefined,

  // type `other` rules
  otherRules: [],

  // appliance timezone
  timezone: undefined,

  fetch: async (): Promise<CalendarRule | undefined> => {
    let schoolTimeRule,
      timezone,
      statusCode = 200;

    const params = new URLSearchParams({ type: String(RuleType.SCHOOL_TIME) });

    // eslint-disable-next-line
    await Api.getAsync(`${calendarRulesURL()}?${params.toString()}`).then(
      (response: { rules: CalendarRule[]; applianceTimezone: string }) => {
        schoolTimeRule = response.rules.find((r) => r.type === RuleType.SCHOOL_TIME);
        timezone = response.applianceTimezone;
      },
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (statusCode !== 200) {
      throw new ResponseError("Failed to fetch calendar rules", statusCode);
    }

    // Calendar rule doesn't exist, return without throwing
    if (!schoolTimeRule) {
      return undefined;
    }

    set({ schoolTimeRule, timezone });

    return schoolTimeRule;
  },

  fetchNonSchoolDays: async (year: number): Promise<NonSchoolRule[]> => {
    let otherRules: NonSchoolRule[] | undefined,
      timezone,
      statusCode = 200;

    const params = new URLSearchParams({ year: String(year), limit: "1000" });

    // eslint-disable-next-line
    await Api.getAsync(`${calendarRulesURL()}?${params.toString()}`).then(
      (response: { rules: CalendarRule[]; applianceTimezone: string }) => {
        otherRules = response.rules.filter(CalendarRuleIsNonSchoolRule);
        timezone = response.applianceTimezone;
      },
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (!otherRules || statusCode !== 200) {
      throw new ResponseError("Failed to fetch calendar rules", statusCode);
    }

    set({ otherRules, timezone });

    return otherRules;
  },

  save: async (rule: CalendarRule, options?: SaveOptions): Promise<CalendarRule> => {
    const isNew = !!rule.id;

    let saved: CalendarRule | undefined,
      statusCode = 200;

    if (options && options.fixVersionConflict) {
      // eslint-disable-next-line
      await Api.getAsync(calendarRulesURL()).then(
        (response: { rules: CalendarRule[] }) => {
          rule.version = response.rules.find((r) => r.id === rule.id)?.version;
        },
        (reason: jqXHR) => (statusCode = reason?.status)
      );

      if (statusCode !== 200) {
        throw new ResponseError("Failed to save calendar rule", statusCode);
      }
    }

    const params = new URLSearchParams();
    if (options && options.recurrenceId) {
      params.append("recurrenceId", String(options.recurrenceId));
      if (options.updateType) {
        params.append("thisAndFuture", options.updateType);
      }
    }

    if (rule.id) {
      // eslint-disable-next-line
      await Api.putAsync(`${calendarRulesURL()}/${rule.id}?${params.toString()}`, rule).then(
        (response: CalendarRule) => (saved = response),
        (reason: jqXHR) => (statusCode = reason?.status)
      );
    } else {
      // eslint-disable-next-line
      await Api.postAsync(calendarRulesURL(), rule).then(
        (response: CalendarRule) => (saved = response),
        (reason: jqXHR) => (statusCode = reason?.status)
      );
    }

    if (!saved || statusCode !== 200) {
      throw new ResponseError("Failed to save calendar rule", statusCode);
    }

    if (saved.type === RuleType.SCHOOL_TIME) {
      set({ schoolTimeRule: saved });
    } else if (CalendarRuleIsNonSchoolRule(saved)) {
      if (isNew) {
        set({ otherRules: [...get().otherRules, saved] });
      } else {
        const rules: NonSchoolRule[] = [];

        for (const r of get().otherRules) {
          if (r.id === saved.id) {
            rules.push(saved);
          } else {
            rules.push(r);
          }
        }

        set({ otherRules: rules });
      }
    }

    return saved;
  },

  delete: async (rule: NonSchoolRule, options?: DeleteOptions): Promise<void> => {
    let statusCode = 200;

    const params = new URLSearchParams();
    if (options && options.recurrenceId) {
      params.append("recurrenceId", String(options.recurrenceId));
      if (options.deleteType) {
        params.append("thisAndFuture", options.deleteType);
      }
    }

    // eslint-disable-next-line
    await Api.deleteAsync(`${calendarRulesURL()}/${rule.id}?${params.toString()}`).then(
      () => ({}),
      (reason: jqXHR) => (statusCode = reason?.status)
    );

    if (statusCode !== 204 && statusCode !== 200) {
      throw new ResponseError("Failed to delete calendar rule", statusCode);
    }

    // cannot delete `school_time` rule hence not handled here

    const rules: NonSchoolRule[] = [];

    for (const r of get().otherRules) {
      if (r.id !== rule.id) {
        rules.push(r);
      }
    }

    set({ otherRules: rules });
  },

  reset: () => {
    set({ schoolTimeRule: undefined, otherRules: [] });
  },
}));
