import React from "react";
import PropTypes from "prop-types";
import RootNavItem from "./components/RootNavItem";
import { Flex, withTranslation } from "@familyzone/component-library";
import AuthorizationWrapper from "../../modules/AuthorizationWrapper";
import uuid from "uuid/v4";
import { getNavRoutes } from "./navRoutes";
import { getApplianceNavRoutes } from "./applianceRoutes";

class Nav extends React.Component {
  constructor(props, context) {
    super(props, context);

    this.state = {
      navItems: [],
    };
  }

  static getDerivedStateFromProps(props, state) {
    if (props.path === "/devices" || props.path === "/device/create" || props.path === "/account") {
      return { navItems: getApplianceNavRoutes(props.t) };
    }
    return { navItems: getNavRoutes(props.t, props.menuFeatureToggles) };
  }

  is_selected = (path) => {
    return !!this.props.path.startsWith(path);
  };

  renderNavItems() {
    return this.state.navItems
      .filter((rootItem) => !rootItem.hidden)
      .map((rootItem) => (
        <AuthorizationWrapper allowedRoles={rootItem.allowedRoles} key={uuid()}>
          <RootNavItem
            name={rootItem.name}
            to={rootItem.to}
            icon={rootItem.icon}
            childItems={rootItem.childItems}
            dataCy={rootItem.dataCy}
            isActive={this.is_selected(rootItem.to)}
            forceShowChildren={rootItem.forceShowChildren || this.props.path.startsWith(rootItem.to)}
            currentPath={this.props.path}
            isNew={rootItem.isNew}
          />
        </AuthorizationWrapper>
      ));
  }

  render() {
    return (
      <React.Fragment>
        {this.props.path !== "/devices" && (
          <Flex direction="column" bg="neutrals.900" height="auto" width="220px" flexShrink="0" position="sticky" top="0">
            <Flex
              direction="column"
              overflowY="scroll"
              overflowX="hidden"
              css={{
                msOverflowStyle: "none" /* Internet Explorer 10+ */,
                scrollbarWidth: "none" /* Firefox */,
                "&::-webkit-scrollbar": {
                  display: "none" /* Safari and Chrome */,
                },
              }}
            >
              {this.renderNavItems()}
            </Flex>
          </Flex>
        )}
      </React.Fragment>
    );
  }
}

Nav.contextTypes = {
  router: PropTypes.object.isRequired,
};

export default withTranslation()(Nav);
