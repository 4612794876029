// Typescript signatures for methods defined in Api.js

enum ErrorType {
  "timeout",
  "error",
  "abort",
  "parsererror",
}

export interface jqXHR {
  status: number;
  responseJSON?: Record<string, string>;
}

export type GetRequest = (url: string, success: (data: unknown) => void, error: (err: ErrorType | null, jqXHR: jqXHR) => void) => void;

export type PutRequest = (
  url: string,
  data: unknown,
  success: (_?: unknown) => void,
  error: (err: ErrorType | null, status: number) => void
) => void;

export type PostRequest = (
  url: string,
  data: unknown,
  success: (data?: unknown) => void,
  error: (jqXHR: jqXHR, err: ErrorType | null) => void
) => void;

export type DeleteRequest = (
  url: string,
  body: unknown,
  success: (data?: unknown) => void,
  error: (err: ErrorType | null, jqXHR: jqXHR) => void
) => void;

export type FileUploadRequest = (
  url: string,
  file: FormData,
  success: (_: unknown) => void,
  error: (jqXHR: jqXHR, err: ErrorType | null) => void
) => void;

export class ResponseError extends Error {
  status: number;

  constructor(message: string, status: number) {
    super(message);
    Object.setPrototypeOf(this, ResponseError.prototype);
    this.status = status;
  }

  toString = (): string => {
    return this.message + " (status " + String(this.status) + ")";
  };
}

/**
 * Extracts {@code error} field from API response if it returns a JSON response.
 *
 * @param jqXHR jQuery HTTP request-response object
 * @returns error message from response body or an empty string
 */
export function getErrorFromResponse(jqXHR: jqXHR): string {
  try {
    if (typeof jqXHR.responseJSON?.error !== "string") {
      return "";
    }
    return jqXHR.responseJSON.error;
  } catch (err) {
    console.warn(err);
  }
  return "";
}
