import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/form-control";
import { Box, Button, Checkbox, Flex, Input, Text, useToast, useTranslation, Link } from "@familyzone/component-library";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { capitalise } from "../../helpers/stringHelpers";
import { useSchoolCalendarStore } from "../../storez/SchoolCalendarStore";
import { ResponseError } from "../../types/Api";
import { Link as ReactLink } from "react-router";
import { CalendarRule, CalendarRuleEquals, Frequency, SchoolTimePlaceholder, Weekday } from "../../types/Calendar";
import { convertToIntegerTime, convertToPrintableTime } from "../../utils/DateTimeUtil";
import SaveConflictErrorModal from "../modals/SaveConflictErrorModal";
import SchoolCalendarSetupModal from "../modals/SchoolCalendarSetupModal";
import CardBasedPage from "../templates/CardBasedPage";
import NonSchoolDays from "./Calendar/NonSchoolDays";
import { useDevicePermissionsStore } from "../../storez/DevicePermissionsStore";
import { isCommunityAuditsAccessible } from "../debug/ConfigAudit/AuditUtils";
import { AuditScope } from "../../types/Audit";
import SessionStore from "../../stores/SessionStore";

const SchoolCalendar: React.FC = () => {
  const { t } = useTranslation();
  const { successToast, errorToast } = useToast();

  const title = t("School Calendar");
  const breadcrumbs = [
    { title: t("Configuration"), url: "/config", isActive: false },
    { title: t("School Calendar"), isActive: true },
  ];

  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [showSetupModal, setShowSetupModal] = useState<boolean>(false);
  const [showConflictModal, setShowConflictModal] = useState<boolean>(false);

  const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
  const [schoolTime, setSchoolTime] = useState<CalendarRule>(SchoolTimePlaceholder);
  const [startTime, setStartTime] = useState<string>("");
  const [endTime, setEndTime] = useState<string>("");

  const [storedSchoolTime, fetchSchoolTime, saveSchoolTime, resetStore] = useSchoolCalendarStore(
    useCallback((state) => [state.schoolTimeRule, state.fetch, state.save, state.reset] as const, [])
  );

  const [permissions] = useDevicePermissionsStore(useCallback((state) => [state.permissions] as const, []));

  const showFetchErrorToast = () => {
    errorToast({
      title: t("Please try again"),
      description: t("Failed to load calendar"),
      isClosable: true,
    });
  };

  const showSaveSuccessToast = () => {
    successToast({
      title: t("Update successful"),
      description: t("The calendar has been successfully updated"),
      isClosable: true,
    });
  };

  const showSaveErrorToast = () => {
    errorToast({
      title: t("Please try again"),
      description: t("An unexpected error occurred while saving the calendar"),
      isClosable: true,
    });
  };

  useEffect(() => {
    fetchSchoolTime().then((rule) => {
      if (!rule && !sessionStorage.getItem("cal-setup-seen")) {
        setShowSetupModal(true);
      }
    }, showFetchErrorToast);

    return resetStore;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fetchSchoolTime]);

  useEffect(() => {
    if (storedSchoolTime) {
      setSchoolTime({ ...storedSchoolTime });
      setStartTime(convertToPrintableTime(storedSchoolTime.startTime));
      setEndTime(convertToPrintableTime(storedSchoolTime.endTime));
      setLoading(false);
    } else if (sessionStorage.getItem("cal-setup-seen")) {
      setLoading(false);
    }
  }, [storedSchoolTime]);

  const isValidTime = (time: number): boolean => {
    const minutes = time % 100;
    const hour = (time - minutes) / 100;
    return hour >= 0 && hour <= 23 && minutes >= 0 && minutes <= 59;
  };

  const validate = (): boolean => {
    const errors: Record<string, string> = {},
      startTime = schoolTime?.startTime ?? -1,
      endTime = schoolTime?.endTime ?? -1,
      recurrence = schoolTime?.recurrence;

    if (startTime > -1) {
      if (!isValidTime(startTime)) {
        errors["startTime"] = t("Start time must be in the range of 00:00 and 23:59");
      }
    } else {
      errors["startTime"] = "Start time must be specified in format HH:MM";
    }

    if (endTime > -1) {
      if (!isValidTime(endTime)) {
        errors["endTime"] = t("End time must be in the range of 00:00 and 23:59");
      } else if (Number.isFinite(startTime) && endTime <= startTime) {
        errors["endTime"] = t("End time must be after start time");
      }
    } else {
      if (startTime === -1) {
        errors["startTime"] = "Start and end time must be specified in format HH:MM";
      } else {
        errors["endTime"] = "End time must be specified in format HH:MM";
      }
    }

    if (recurrence?.frequency === Frequency.WEEKLY && (recurrence?.days ?? []).length === 0) {
      errors["recurrenceDays"] = "Either 'All' or specific day(s) must be selected";
    }

    setFieldErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const handleChangeStartTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const timeString = event.target.value;
    setStartTime(timeString);
    setSchoolTime((rule) => (rule ? { ...rule, startTime: convertToIntegerTime(timeString) } : rule));
  };

  const handleChangeEndTime = (event: React.ChangeEvent<HTMLInputElement>) => {
    const timeString = event.target.value;
    setEndTime(timeString);
    setSchoolTime((rule) => (rule ? { ...rule, endTime: convertToIntegerTime(timeString) } : rule));
  };

  const handleChangeAll = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.checked) {
      setSchoolTime((rule) => ({
        ...rule,
        recurrence: {
          ...rule.recurrence,
          interval: 1,
          frequency: Frequency.DAILY,
          days: undefined,
        },
      }));
    } else {
      setSchoolTime((rule) => ({
        ...rule,
        recurrence: {
          ...rule.recurrence,
          interval: 1,
          frequency: Frequency.WEEKLY,
          days: [],
        },
      }));
    }
  };

  const handleChangeDay = (event: React.ChangeEvent<HTMLInputElement>, weekday: Weekday) => {
    if (event.target.checked) {
      setSchoolTime((rule) => {
        const recurrence = {
          ...rule.recurrence,
          interval: 1,
          frequency: Frequency.WEEKLY,
        };
        recurrence.days = recurrence.days ? [...recurrence.days, weekday] : [weekday];
        return { ...rule, recurrence };
      });
    } else {
      setSchoolTime((rule) => {
        const recurrence = {
          ...rule.recurrence,
          interval: 1,
          frequency: Frequency.WEEKLY,
        };
        recurrence.days = recurrence.days ? recurrence.days.filter((d) => d !== weekday) : [];
        return { ...rule, recurrence };
      });
    }
  };

  const handleSave = () => {
    if (!validate()) {
      return;
    }

    setSaving(true);
    saveSchoolTime(schoolTime)
      .then(showSaveSuccessToast, (err: ResponseError) => {
        if (err.status === 409) {
          setShowConflictModal(true);
        } else {
          showSaveErrorToast();
        }
      })
      .finally(() => setSaving(false));
  };

  const handleCloseSetupModal = (): void => {
    sessionStorage.setItem("cal-setup-seen", "true");
    setLoading(false);
    setShowSetupModal(false);
  };

  const handleCloseConflictModal = (): void => {
    setShowConflictModal(false);
  };

  const handleConfirmSave = (): void => {
    setSaving(true);
    saveSchoolTime(schoolTime, { fixVersionConflict: true })
      .then(() => showSaveSuccessToast, showSaveErrorToast)
      .finally(() => {
        setSaving(false);
        setShowConflictModal(false);
      });
  };

  const handleReloadPage = (): void => {
    setLoading(true);
    fetchSchoolTime().then(() => {
      setShowConflictModal(false);
    }, showFetchErrorToast);
  };

  const DayCheckBox: React.FC<{ day: Weekday }> = ({ day }) => {
    const isChecked = schoolTime && !!schoolTime.recurrence?.days?.find((d) => d === day);
    return (
      <Checkbox isChecked={isChecked} onChange={(e) => handleChangeDay(e, day)}>
        {capitalise(day)}
      </Checkbox>
    );
  };

  const hasChanges = () => schoolTime && (!storedSchoolTime || !CalendarRuleEquals(schoolTime, storedSchoolTime));

  // eslint-disable-next-line
  const isSupportAdmin = SessionStore.isSupportAdmin() as boolean;

  const canAccessCommunity = useMemo(() => {
    return isCommunityAuditsAccessible(permissions, isSupportAdmin);
  }, [permissions, isSupportAdmin]);

  const viewHistoryLink = canAccessCommunity ? (
    <>
      <Box textAlign={"right"} lineHeight="31px">
        <Link className="personaModule-rightLink" as={ReactLink} to={"/managedevice/settings/audit/community?scope=" + AuditScope.CALENDAR}>
          {t("View History")}
        </Link>
      </Box>
    </>
  ) : undefined;

  return (
    <>
      <Box flexDirection="column" minWidth="100%">
        <CardBasedPage title={title} tools={viewHistoryLink} breadcrumbs={breadcrumbs}>
          <Box p="sp24" color="neutrals.900">
            <Text fontFamily="Poppins" fontWeight="medium" fontSize="large">
              {t("School Time & Days")}
            </Text>
          </Box>
          <Box px="sp24" color="neutrals.900">
            {t("Set school hours and days. This will define the school hours for Pause Internet and Reporting options.")}
          </Box>
          <Box p="sp24">
            <FormControl mb="sp32" isDisabled={loading || saving} isInvalid={!!fieldErrors.startTime || !!fieldErrors.endTime}>
              <Flex mb="sp32">
                <Box w="150px" mr="sp16">
                  <Text>{t("Time (24 hours)")}</Text>
                  <FormHelperText color="neutrals.200">{t("For example, 8:30 to 22:00")}</FormHelperText>
                </Box>
                <Flex flexDirection="column">
                  <Flex>
                    <Input
                      py="sp8"
                      w="150px"
                      placeholder="HH:MM"
                      value={startTime}
                      onChange={handleChangeStartTime}
                      aria-label="Start Time"
                    />
                    <Text display="flex" alignItems="center" px="sp8">
                      {t("to")}
                    </Text>
                    <Input py="sp8" w="150px" placeholder="HH:MM" value={endTime} onChange={handleChangeEndTime} aria-label="End Time" />
                  </Flex>
                  <FormErrorMessage py="sp8" fontWeight="regular" flexDirection="column" alignItems="start">
                    {fieldErrors.startTime && <Text>{fieldErrors.startTime}</Text>}
                    {fieldErrors.endTime && <Text>{fieldErrors.endTime}</Text>}
                  </FormErrorMessage>
                </Flex>
              </Flex>
            </FormControl>
            <FormControl isDisabled={loading || saving} isInvalid={!!fieldErrors.recurrenceDays}>
              <Flex>
                <FormLabel w="150px" mr="sp16">
                  {t("Days")}
                </FormLabel>
                <Flex flexDirection="column">
                  <Flex>
                    <Input type="hidden" />
                    <Flex backgroundColor="neutrals.20" p="12px" mr="sp4" borderRadius="6px 0 0 6px">
                      <Checkbox isChecked={schoolTime?.recurrence?.frequency === Frequency.DAILY} onChange={handleChangeAll}>
                        {t("All")}
                      </Checkbox>
                    </Flex>
                    <Flex backgroundColor="neutrals.20" p="12px" borderRadius="0 6px 6px 0" gap="sp16">
                      <DayCheckBox day={Weekday.MONDAY} />
                      <DayCheckBox day={Weekday.TUESDAY} />
                      <DayCheckBox day={Weekday.WEDNESDAY} />
                      <DayCheckBox day={Weekday.THURSDAY} />
                      <DayCheckBox day={Weekday.FRIDAY} />
                      <DayCheckBox day={Weekday.SATURDAY} />
                      <DayCheckBox day={Weekday.SUNDAY} />
                    </Flex>
                  </Flex>
                  <FormErrorMessage py="sp8" fontWeight="regular">
                    <Text>{fieldErrors.recurrenceDays}</Text>
                  </FormErrorMessage>
                </Flex>
              </Flex>
            </FormControl>
          </Box>
          <Box p="sp24" backgroundColor="brand.100" borderBottomRadius="sm">
            <Button variant="primary" disabled={loading || saving || !hasChanges()} onClick={handleSave}>
              Save School Time & Days
            </Button>
          </Box>
        </CardBasedPage>
        <NonSchoolDays nonSchoolDaysEnabled={!!storedSchoolTime} />
      </Box>
      <SchoolCalendarSetupModal open={showSetupModal} onClose={handleCloseSetupModal} />
      <SaveConflictErrorModal
        open={showConflictModal}
        onClose={handleCloseConflictModal}
        onSave={handleConfirmSave}
        onReload={handleReloadPage}
      />
    </>
  );
};

export default SchoolCalendar;
