import $ from "jquery";
import uuid from "uuid/v4";

import { useDateRangeFilterStore } from "../storez/DateRangeFilterStore";
import SessionStore from "../stores/SessionStore";
import SessionActions from "../actions/SessionActions";
import ApiActions from "../actions/ApiActions";
import { addGCParam } from "./GCUtil";

export default class Api {
  static unauthenticated_post(url, data, callback_success, callback_error) {
    let request_id = uuid();
    ApiActions.incrementRequestCounter.defer(request_id);
    $.ajax({
      url,
      type: "post",
      dataType: "json",
      contentType: "application/json; charset=utf-8",
      success: function (data) {
        callback_success(data);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      error: function (jqXHR, exception) {
        if (callback_error) callback_error(exception, jqXHR);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      data: JSON.stringify(data),
    });
  }

  static get(url, callback_success, callback_error) {
    this.get_with_timeout(url, callback_success, callback_error, 0);
  }

  static getAsync(url) {
    return new Promise((resolve, reject) => {
      this.get(url, resolve, (err, jqXHR) => reject(jqXHR));
    });
  }

  static getAsyncWithTimeout(url, timeoutInMillis) {
    return new Promise((resolve, reject) => {
      this.get_with_timeout(url, resolve, (err, jqXHR) => reject(jqXHR), timeoutInMillis);
    });
  }

  static get_with_timeout(url, callback_success, callback_error, timeout_in_millis) {
    let request_id = uuid();
    ApiActions.incrementRequestCounter.defer(request_id);
    let headers = {
      Device: SessionStore.getSelectedDevice(),
      Token: SessionStore.getToken(),
      UserId: SessionStore.getUserId(),
      IsSupportAdmin: SessionStore.isSupportAdmin(),
    };

    if (addGCParam()) {
      headers["gc"] = "True";
    }

    $.ajax({
      url,
      type: "get",
      dataType: "json",
      headers: headers,
      contentType: "application/json; charset=utf-8",
      success: function (data) {
        callback_success(data);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      error: function (jqXHR, exception) {
        if (jqXHR.status === 403) {
          SessionActions.invalidSessionError(exception);
        }

        if (callback_error) callback_error(exception, jqXHR);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      timeout: timeout_in_millis,
    });
  }

  static get_raw(url, callback_success, callback_error) {
    let request_id = uuid();
    ApiActions.incrementRequestCounter.defer(request_id);
    let headers = {
      Device: SessionStore.getSelectedDevice(),
      Token: SessionStore.getToken(),
      UserId: SessionStore.getUserId(),
    };

    if (addGCParam()) {
      headers["gc"] = "True";
    }

    $.ajax({
      url,
      type: "get",
      headers: headers,
      success: function (data) {
        callback_success(data);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      error: function (jqXHR, exception) {
        if (jqXHR.status === 403) {
          SessionActions.invalidSessionError(exception);
        }

        if (callback_error) callback_error(exception);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
    });
  }

  static get_analytics(url, callback_success, callback_error, options = {}) {
    const filterStore = useDateRangeFilterStore.getState();
    let request_id = uuid();
    ApiActions.incrementRequestCounter.defer(request_id);
    let uri = url;
    if (url.indexOf("?") === -1) {
      uri += "?";
    }

    if (options.noiseOverride !== undefined) {
      uri += "&include_noise=" + options.noiseOverride;
    } else {
      uri += "&include_noise=" + filterStore.getIncludeNoise();
    }

    if (options.dateOverride) {
      uri += "&startDate=" + options.dateOverride.startDate;
      uri += "&endDate=" + options.dateOverride.endDate;
      uri += "&startTime=" + options.dateOverride.startTime;
      uri += "&endTime=" + options.dateOverride.endTime;
    } else if (url.indexOf("overrideDates") === -1) {
      uri += "&startDate=" + filterStore.getStartDate();
      uri += "&endDate=" + filterStore.getEndDate();
      uri += "&startTime=" + filterStore.getStartTime();
      uri += "&endTime=" + filterStore.getEndTime();
    }

    let headers = {
      Device: SessionStore.getSelectedDevice(),
      Token: SessionStore.getToken(),
      UserId: SessionStore.getUserId(),
    };

    if (addGCParam()) {
      headers["gc"] = "True";
    }

    $.ajax({
      url: uri,
      type: "get",
      dataType: "json",
      headers: headers,
      contentType: "application/json; charset=utf-8",
      success: function (data) {
        callback_success(data);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      error: function (jqXHR, exception) {
        if (jqXHR.status === 403) {
          SessionActions.invalidSessionError(exception);
        }

        if (callback_error) callback_error(exception);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
    });
  }

  static get_analyticsAsync(url, options = {}) {
    return new Promise((resolve, reject) => {
      this.get_analytics(url, resolve, reject, options);
    });
  }

  static post_analytics(url, data, callback_success, callback_error) {
    const filterStore = useDateRangeFilterStore.getState();
    let request_id = uuid();
    ApiActions.incrementRequestCounter.defer(request_id);

    let uri = url;
    if (url.indexOf("?") === -1) {
      uri += "?";
    }

    const noise = filterStore.getIncludeNoise() ? "true" : "false";

    uri += "&include_noise=" + noise;
    if (url.indexOf("overrideDates") === -1) {
      uri += "&startDate=" + filterStore.getStartDate();
      uri += "&endDate=" + filterStore.getEndDate();
      uri += "&startTime=" + filterStore.getStartTime();
      uri += "&endTime=" + filterStore.getEndTime();
    }

    let headers = {
      Device: SessionStore.getSelectedDevice(),
      Token: SessionStore.getToken(),
      UserId: SessionStore.getUserId(),
    };

    if (addGCParam()) {
      headers["gc"] = "True";
    }

    return $.ajax({
      url: uri,
      type: "post",
      dataType: "json",
      headers: headers,
      contentType: "application/json; charset=utf-8",
      success: function (data) {
        callback_success(data);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      error: function (jqXHR, exception) {
        if (jqXHR.status === 403) {
          SessionActions.invalidSessionError(exception);
        }

        if (callback_error) callback_error(jqXHR, exception);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      data: JSON.stringify(data),
    });
  }

  static delete(url, data, callback_success, callback_error) {
    let request_id = uuid();
    ApiActions.incrementRequestCounter.defer(request_id);
    let headers = {
      Device: SessionStore.getSelectedDevice(),
      Token: SessionStore.getToken(),
      UserId: SessionStore.getUserId(),
    };

    $.ajax({
      url,
      type: "delete",
      dataType: "json",
      headers: headers,
      contentType: "application/json; charset=utf-8",
      success: function (data) {
        callback_success(data);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      error: function (jqXHR, exception) {
        if (jqXHR.status === 403) {
          SessionActions.invalidSessionError(exception);
        }

        if (callback_error) callback_error(exception);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      data: JSON.stringify(data),
    });
  }

  static deleteAsync(url, data) {
    return new Promise((resolve, reject) => {
      this.delete(url, data, resolve, (err, jqXHR) => reject(jqXHR));
    });
  }

  static post(url, data, callback_success, callback_error) {
    let request_id = uuid();
    ApiActions.incrementRequestCounter.defer(request_id);
    let headers = {
      Device: SessionStore.getSelectedDevice(),
      Token: SessionStore.getToken(),
      UserId: SessionStore.getUserId(),
    };

    if (addGCParam()) {
      headers["gc"] = "True";
    }

    return $.ajax({
      url,
      type: "post",
      dataType: "json",
      headers: headers,
      contentType: "application/json; charset=utf-8",
      success: function (data) {
        if (callback_success) callback_success(data);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      error: function (jqXHR, exception) {
        if (jqXHR.status === 403) {
          SessionActions.invalidSessionError(exception);
        }

        if (callback_error) callback_error(jqXHR, exception);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      data: JSON.stringify(data),
    });
  }

  static postAsync(url, data) {
    return new Promise((resolve, reject) => {
      this.post(url, data, resolve, reject);
    });
  }

  static put(url, data, callback_success, callback_error) {
    let request_id = uuid();
    ApiActions.incrementRequestCounter.defer(request_id);
    let headers = {
      Device: SessionStore.getSelectedDevice(),
      Token: SessionStore.getToken(),
      UserId: SessionStore.getUserId(),
    };

    return $.ajax({
      url,
      type: "put",
      dataType: "json",
      headers: headers,
      contentType: "application/json; charset=utf-8",
      success: function (data) {
        if (callback_success) callback_success(data);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      error: function (jqXHR, exception) {
        if (jqXHR.status === 403) {
          SessionActions.invalidSessionError(exception);
        }

        if (callback_error) callback_error(exception, jqXHR.status, jqXHR);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      data: JSON.stringify(data),
    });
  }

  static putAsync(url, data) {
    return new Promise((resolve, reject) => {
      this.put(url, data, resolve, (err, status, jqXHR) => reject(jqXHR ?? { status }));
    });
  }

  static post_file(url, file, callback_success, callback_error) {
    let request_id = uuid();
    ApiActions.incrementRequestCounter.defer(request_id);
    let headers = {
      Device: SessionStore.getSelectedDevice(),
      Token: SessionStore.getToken(),
      UserId: SessionStore.getUserId(),
    };

    return $.ajax({
      url,
      type: "post",
      contentType: false,
      headers: headers,
      cache: false,
      processData: false,
      success: function (data) {
        if (callback_success) callback_success(data);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      error: function (jqXHR, exception) {
        if (jqXHR.status === 403) {
          SessionActions.invalidSessionError(exception);
        }

        if (callback_error) callback_error(jqXHR, exception);
        ApiActions.decrementRequestCounter.defer(request_id);
      },
      data: file,
    });
  }
}
