import { Modal, ModalBody, Text, useTranslation } from "@familyzone/component-library";
import React, { useEffect, useState } from "react";
import { DisconnectEvent, DisconnectType } from "../../types/Community";

interface Props {
  event: DisconnectEvent;
  open: boolean;
  onClose: () => void;
  onConfirm: (event: DisconnectEvent) => void;
}

const headerTextMap = {
  [DisconnectType.DisconnectStudentFromParent]: "Disconnect Student",
  [DisconnectType.DisconnectParentFromStudent]: "Disconnect Parent",
  [DisconnectType.UnlinkStudentFromParent]: "Remove Student",
  [DisconnectType.UnlinkParentFromStudent]: "Remove Parent",
};

const ParentDisconnectModal: React.FC<Props> = ({ event, open, onClose, onConfirm }: Props) => {
  const { t } = useTranslation();

  const [saving, setSaving] = useState<boolean>(false);

  const handleConfirm = (): void => {
    setSaving(true);
    onConfirm(event);
  };

  useEffect(() => {
    if (open) {
      setSaving(false);
    }
  }, [open]);

  const studentName = event.student.firstName + " " + event.student.lastName;
  const guardianName = event.guardian.firstName + " " + event.guardian.lastName;
  const disconnecting =
    event.type === DisconnectType.DisconnectStudentFromParent || event.type === DisconnectType.DisconnectParentFromStudent;

  return (
    <Modal
      isOpen={open}
      onClose={onClose}
      variant="warning"
      size="md"
      headerText={t(headerTextMap[event.type])}
      primaryCTALabel={disconnecting ? (saving ? t("Disconnecting...") : t("Disconnect")) : saving ? t("Removing...") : t("Remove")}
      primaryCTADisabled={saving}
      onPrimaryCTAClick={handleConfirm}
      secondaryCTALabel={t("Cancel")}
      secondaryCTADisabled={saving}
      onSecondaryCTAClick={onClose}
    >
      <ModalBody>
        <>
          {event.type === DisconnectType.DisconnectParentFromStudent && (
            <>
              <Text mb="sp24">
                {t("Disconnecting a parent from a student means the parent will not be able to see the student school time activity.")}
              </Text>
              {event.guardian.delegated && <Text mb="sp24">{t("It will also disable Parental Controls.")}</Text>}
              <Text data-testid="confirmation-question">
                {t("Are you sure you want to disconnect parent")} <b>{guardianName}</b> {t("from student")} <b>{studentName}</b>?
              </Text>
            </>
          )}

          {event.type === DisconnectType.UnlinkParentFromStudent && (
            <>
              <Text mb="sp24">{t("This will remove the parent from the student completely.")}</Text>
              <Text data-testid="confirmation-question">
                {t("Are you sure you want to remove parent")} <b>{guardianName}</b> {t("from student")} <b>{studentName}</b>?
              </Text>
            </>
          )}

          {event.type === DisconnectType.DisconnectStudentFromParent && (
            <>
              <Text mb="sp24">
                {t("Disconnecting a student from a parent means the parent will not be able to see student's school time activity.")}
              </Text>
              {event.student.delegated && <Text mb="sp24">{t("It will also disable Parental Controls.")}</Text>}
              <Text data-testid="confirmation-question">
                {t("Are you sure you want to disconnect student")} <b>{studentName}</b> {t("from parent")} <b>{guardianName}</b>?
              </Text>
            </>
          )}

          {event.type === DisconnectType.UnlinkStudentFromParent && (
            <>
              <Text mb="sp24">{t("This will remove the student from the parent completely.")}</Text>
              <Text data-testid="confirmation-question">
                {t("Are you sure you want to remove student")} <b>{studentName}</b> {t("from parent")} <b>{guardianName}</b>?
              </Text>
            </>
          )}
        </>
      </ModalBody>
    </Modal>
  );
};

export default ParentDisconnectModal;
