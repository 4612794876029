import React from "react";

import SearchableSelect from "../modules/SearchableSelect";
import BetaTag from "./BetaTag";
import InfoIconV2 from "./InfoIconV2";
import { Flex } from "@familyzone/component-library";
import { useFeatureFlagStore } from "../storez/FeatureFlagStore";

class FeatureSelectedOption extends React.Component {
  handleRemove = () => {
    this.props.handleRemove(this.props.value);
  };

  render() {
    let betaTag = this.props.isBeta ? <BetaTag /> : null;
    let featureInfo = this.props.featureInfo ? (
      <a href={this.props.featureInfo.link} rel="noreferrer" target="_blank">
        <InfoIconV2 alignItems={"center"} backgroundColor="grey" text={this.props.featureInfo.text}>
          {this.props.featureInfo.text}
        </InfoIconV2>
      </a>
    ) : null;

    return (
      <Flex alignItems={"center"} className="listselector-item">
        <span className="signature_item_remove selected-item-remove" onClick={this.handleRemove}>
          <i className="fa fa-times" aria-hidden="true" />
        </span>
        {this.props.name}
        {betaTag}
        {featureInfo}
      </Flex>
    );
  }
}

export default class ClasswizeFeatureSelector extends React.Component {
  constructor(props, context) {
    super(props, context);
    this.state = {
      features: [
        "lock",
        "open_tab",
        "close_tab",
        "focus",
        "bypass_code",
        "reward",
        "message_class",
        "message_student",
        "policies",
        "add_user",
        "schedules",
        "native_app_controls",
      ],
      selectedOptions: this.props.selected ? this.props.selected : [],
      featureFlags: useFeatureFlagStore.getState().flags ?? {},
    };
  }

  componentDidMount() {
    useFeatureFlagStore
      .getState()
      .getOrFetch()
      .then((flags) => {
        this.setState((state) => ({ ...state, featureFlags: flags }));
      });
  }

  componentWillReceiveProps(nextprops) {
    if (this.props.selected !== nextprops.selected) {
      this.setState({
        selectedOptions: nextprops.selected,
      });
    }
  }

  handle_AddSelection = (event) => {
    this.props.addElement(event.value);
    let updatedOptions = this.state.selectedOptions;
    if (updatedOptions.indexOf(event.value) < 0) {
      updatedOptions.push(event.value);
      this.setState({ selectedOptions: updatedOptions });
    }
  };

  handle_RemoveSelection = (item) => {
    this.props.removeElement(item);

    let updatedOptions = this.state.selectedOptions;
    for (let optionIndex in updatedOptions) {
      if (item === updatedOptions[optionIndex]) {
        updatedOptions.splice(optionIndex, 1);
        break;
      }
    }
    this.setState({ selectedOptions: updatedOptions });
  };

  get_feature_name = (key) => {
    const features = {
      lock: "Pause Internet",
      open_tab: "Open Site",
      close_tab: "Close Tab",
      focus: "Focus",
      bypass_code: "Bypass Code",
      reward: "Reward",
      message_class: "Class Announcement (one-way)",
      message_student: "Message Student (one-way)",
      policies: "Classwize Rules",
      add_user: "Modify Students in Class",
      schedules: "Class Schedules",
      native_app_controls: "Native App Controls (Windows only)",
    };
    return features[key];
  };

  get_feature_info = (key) => {
    const features = {
      native_app_controls: {
        text: "Teachers can block native apps on student’s windows devices within a classroom",
        link: "https://help.classwize.net/hc/en-gb/articles/8673925323036",
      },
    };
    return features[key];
  };

  render_SelectedOptions = () => {
    let ret = [];
    let feature_name, feature_info;
    if (!this.props.selected) {
      return ret;
    }

    for (let x of this.props.selected) {
      if (x === "") {
        continue;
      }

      feature_name = this.get_feature_name(x);
      feature_info = this.get_feature_info(x);

      // Currently everything that is in featureFlags are beta. This might change later.
      let isBeta = this.state.featureFlags[x] ? true : false;
      if (feature_name) {
        ret.push(
          <FeatureSelectedOption
            key={x}
            value={x}
            name={feature_name}
            isBeta={isBeta}
            handleRemove={this.handle_RemoveSelection}
            featureInfo={feature_info}
          />
        );
      }
    }

    return ret;
  };

  get_SelectionMap = () => {
    let ret = [];
    if (!this.state.features) {
      return ret;
    }

    let availableFeatures = new Set(this.state.features);

    // We only include feature for Classwize if it's enabled at SM
    Object.keys(this.state.featureFlags).forEach(function (key, value) {
      if (value) {
        availableFeatures.add(key);
      }
    });

    for (let option of availableFeatures) {
      if (this.props.selected) {
        if (this.props.selected.includes(option)) {
          continue;
        }
      }
      let feature_name = this.get_feature_name(option);
      if (feature_name) {
        ret.push([option, feature_name]);
      }
    }

    return ret;
  };

  render() {
    return (
      <div>
        <div className="mui-select">
          <SearchableSelect
            disabled={this.props.disabled}
            value={""}
            clearable={false}
            placeholder={"Features"}
            onChange={this.handle_AddSelection}
            valueDataMap={this.get_SelectionMap()}
          />
          <label>{this.props.label}</label>
        </div>
        <div>{this.render_SelectedOptions()}</div>
      </div>
    );
  }
}
